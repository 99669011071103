import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import styles from "./Profile.module.css";
import { Doughnut } from "react-chartjs-2";

import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { api, setAuthToken } from "../../../Api";
import { useSelector, useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../util/Toaster/Toaster";
import { setUser } from "../../../Redux/actions/userActions";

import Notfound from "../../../commonComponents/Notfound";
import Goback from "../../../commonComponents/Goback";

import TransactionTab from "./TransactionTab";
import ReferralsGivenTable from "../UserProfile/ReferralsGiven";

import {
  StyledTab,
  StyledTabs,
} from "../../../pageComponets/profile/TabStyles";

import { getElementById } from "../../../helpers/productList";
import TabPanel from "../../../pageComponets/profile/TabPanel";
import ReferralsGivenTeamTable from "../UserProfile/ReferralsGivenTeam";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const { userId } = useParams();
  const [referrals, setReferrals] = useState([]);
  const [totalCommissionEarned, setTotalCommissionEarned] = useState(0);
  const [value, setValue] = useState(0); //tabs value
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [transections, setTransections] = useState(null);
  const [paginationReferrals, setPaginationReferrals] = useState({
    page_no: 1,
    data_per_page: 10,
    total_pages: 0,
  });
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    navigate(`/profile/payments/${userId}`, {
      state: { totalCommission: totalCommissionEarned },
    });
  };

  const fetchReferrals = async () => {
    try {
      setAuthToken(localStorage.getItem("loggedUserToken"));
      const response = await api.get(`/referrals/${userId}`, {
        referral_id: userData?.referral_id,
      });

      setPaginationReferrals({
        ...paginationReferrals,
        total_pages: Math.ceil(
          response.data.totalCount / paginationReferrals.data_per_page
        ),
      });
      if (paginationReferrals.page_no === 1) {
        setDisablePrev(true);
      } else {
        setDisablePrev(false);
      }
      if (paginationReferrals.page_no === paginationReferrals.total_pages) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }
      const resp_referrals = response.data.referrals;
      setReferrals(resp_referrals);
      calculateTotalAmount(resp_referrals);
      // notifySuccess(response.data.message);
      // setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expired, delete token and redirect to login page
        localStorage.removeItem("loggedUserToken");
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login", { replace: true });
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong!");
      }
    }
  };

  const fetchTransaction = async (search) => {
    api
      .get(`/payments/${params.userId}`)
      .then((response) => {
        const { payments, totalCount } = response.data;
        setTransections(payments);
        // notifySuccess(response.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          notifyError(error.response.data.message);
        } else {
          notifyError("Something went wrong!");
        }
      });
  };

  useEffect(() => {
    if (userData) {
      fetchReferrals();
    }
  }, [paginationReferrals.page_no, userData]);

  useEffect(() => {
    if (searchParams.get("tab") == 1) {
      setValue(1);
    }
    fetchTransaction();
  }, []);

  const countReferralIDFrequency = () => {
    const referralIDCounts = {};

    referrals.forEach((referral) => {
      const { referral_id } = referral;
      if (referralIDCounts[referral_id]) {
        referralIDCounts[referral_id] += 1;
      } else {
        referralIDCounts[referral_id] = 1;
      }
    });

    const sortedFrequencies = Object.entries(referralIDCounts)
      .sort((a, b) => b[1] - a[1])
      .reduce(
        (acc, [id, frequency]) => {
          acc.labels.push(id);
          acc.data.push(frequency);
          return acc;
        },
        { labels: [], data: [] }
      );

    return sortedFrequencies;
  };

  const referralIDData = countReferralIDFrequency();
  // Generate data and options for the chart
  const chartData = {
    labels: ["Pending", "Qualified", "Converted", "Rejected"],
    datasets: [
      {
        label: "Lead Status",
        data: [
          referrals.filter((referral) => referral.lead_status === 0)?.length,
          referrals.filter((referral) => referral.lead_status === 1)?.length,
          referrals.filter((referral) => referral.lead_status === 2)?.length,
          referrals.filter((referral) => referral.lead_status === 3)?.length,
        ],
        backgroundColor: [
          "rgb(54, 162, 235)", // Blue for Pending
          "rgb(255, 205, 86)", // Yellow for Qualified
          "	rgb(50,205,50)", // Green for Converted
          "rgb(255, 99, 132)", // Red for Rejected
        ],
        borderColor: [
          "rgba(255, 165, 0, 1)",
          "rgba(255, 255, 0, 1)",
          "rgba(0, 128, 0, 1)",
          "rgba(255, 0, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    elements: {
      arc: {
        backgroundColor: "green", // Custom canvas background color
        hoverOffset: 15, // Hover offset in pixels
      },
    },
  };
  const chartData1 = {
    labels: referralIDData.labels,
    datasets: [
      {
        label: "Referral ID Frequency",
        data: referralIDData.data,
        fill: false,
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const options1 = {
    indexAxis: "y",
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        precision: 0, // Set precision to 0 to display whole numbers
      },
    },
  };

  const totalAmount = referrals.reduce(
    (total, referral) => total + referral.amount,
    0
  );
  const topFiveAmounts = referrals
    .sort((a, b) => b.amount - a.amount)
    .slice(0, 5)
    .map((referral) => referral.amount);

  const chartData2 = {
    labels: ["Total Amount", "Top Five Amounts"],
    datasets: [
      {
        label: "Amount",
        data: [totalAmount, ...topFiveAmounts],
        backgroundColor: [
          "rgba(23, 162, 184, 0.7)",
          ...Array(5).fill("rgba(23, 162, 184, 0.4)"),
        ],
        borderColor: "rgba(23, 162, 184, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options2 = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        precision: 0,
      },
    },
  };

  const handlePrevPage = async () => {
    const currentPage = paginationReferrals.page_no;
    setDisableNext(false);
    setPaginationReferrals((prevState) => ({
      ...prevState,
      page_no: currentPage - 1,
    }));

    if (currentPage - 1 === 1) {
      setDisablePrev(true);
    }
  };

  const handleNextPage = async () => {
    const currentPage = paginationReferrals.page_no;
    const total_page = paginationReferrals.total_pages;

    setDisablePrev(false);
    setPaginationReferrals((prevState) => ({
      ...prevState,
      page_no: currentPage + 1,
    }));

    if (currentPage === total_page - 1) {
      setDisableNext(true);
    }
  };

  const calculateTotalAmount = (resp_referrals) => {
    let total = 0;
    let totalTeamReferralCommision = 0;
    resp_referrals.forEach((payment) => {
      const amount_qualified = parseFloat(payment.commission_qualfied);
      const amount_converted = parseFloat(payment.commission_converted);
      if (payment?.lead_status !== 3) {
        if (!isNaN(amount_converted)) {
          total += amount_converted;
        }
        if (!isNaN(amount_qualified)) {
          total += amount_qualified;
        }
      }
      if (payment.commission) {
        const total_team_referral = parseFloat(payment.commission);
        totalTeamReferralCommision += total_team_referral;
      }
    });
    setTotalCommissionEarned(total);
    // setTotalCommissionEarned(totalTeamReferralCommision);
    return total;
  };
  useEffect(() => {
    if (userId) {
      const fetchUser = async () => {
        try {
          setAuthToken(localStorage.getItem("loggedUserToken"));
          const response = await api.get(`/user/${userId}`);
          setUserData(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            notifyError(error.response.data.message);
            dispatch(setUser(null));
            navigate("/login");
          } else if (error.response) {
            notifyError(error.response.data.message);
          } else {
            notifyError("Something went wrong");
          }
        }
      };

      fetchUser();
    }
  }, [userId]);

  if (userId && !userData) {
    return (
      <div className="loading-container">
        <div className="loading"></div>
        <p>Loading...</p>
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    // handles tab change
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <div style={{ margin: "0px 1rem" }}>
      <Goback />
      {/* <h2 className={styles.heading}>{userData.userName}'s Profile</h2> */}
      {userId ? (
        userData ? (
          <div className={styles.profileInfo}>
            {/* <div className="chart-container">
              <div className="chart-item">
                <h3>Lead Status</h3>
                <Doughnut data={chartData} options={options} />
              </div>
              <div className="chart-item">
                <h3>Referral ID Frequency</h3>
                <Bar data={chartData1} options={options1} />
              </div>
              <div className="chart-item">
                <h3>Total Amount</h3>
                <Bar data={chartData2} options={options2} />
              </div>
            </div> */}
            <div
              className={styles.userInfoContainer}
              style={{ marginTop: "20px" }}
            >
              <p>
                <span className={styles.label}>Full Name:</span>{" "}
                {userData.fullName}
              </p>
              <p>
                <span className={styles.label}>Email:</span> {userData.email}
              </p>
              <p>
                <span className={styles.label}>Phone Number:</span>{" "}
                {userData?.mobile}
              </p>
              <p>
                <span className={styles.label}>Country:</span>{" "}
                {userData?.country}
              </p>
              <p>
                <span className={styles.label}>User ID:</span> {userData.id}
              </p>
            </div>
            <br />
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="division-tabs"
            >
              <StyledTab label="Referrals Given" {...a11yProps(0)} />
              <StyledTab label="Transactions" {...a11yProps(1)} />
            </StyledTabs>
          </div>
        ) : (
          <div>User not found.</div>
        )
      ) : (
        loggedUser && (
          <div className={styles.profileInfo}>
            <p>
              <span className={styles.label}>User Name:</span>{" "}
              {loggedUser.fullName}
            </p>
            {/* <p>
              <span className={styles.label}>First Name:</span>{" "}
              {loggedUser.fullName}
            </p> */}
            {/* <p>
              <span className={styles.label}>Last Name:</span>{" "}
              {loggedUser.lastName}
            </p> */}
            <p>
              <span className={styles.label}>Email:</span> {loggedUser.email}
            </p>
            <p>
              <span className={styles.label}>User ID:</span> {loggedUser.userId}
            </p>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="division-tabs"
            >
              <StyledTab label="Referrals" {...a11yProps(0)} />
              <StyledTab label="Transactions" {...a11yProps(1)} />
            </StyledTabs>
          </div>
        )
      )}
      <TabPanel value={value} index={0}>
        {/* Referral table */}
        <div className={styles.container}>
          {/* <h2 className={styles.heading}>Referrals Given</h2> */}
          <div className="">
            {referrals?.length !== 0 ? (
              <ReferralsGivenTable referrals={{ referrals: referrals }} />
            ) : (
              <Notfound text={"No referrals found."} />
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {" "}
        <TransactionTab
          transections={transections}
          style={{ margin: "0 20px" }}
        />
      </TabPanel>

      {/* <div className="pagination-handler d-flex justify-content-end align-items-center">
        <button
          className="btn btn-outline-primary"
          onClick={handlePrevPage}
          disabled={disablePrev}
        >
          {"<"}
        </button>
        <span style={{ padding: "0px 10px" }}>
          {paginationReferrals.page_no}
        </span>
        <button
          className="btn btn-outline-primary"
          onClick={handleNextPage}
          disabled={disableNext}
        >
          {">"}
        </button>
      </div> */}

      <div className={styles.totalCommissionContainer}>
        Total Commission Earned :{" "}
        <span className={styles.totalCommissionAmount}>
          $ {totalCommissionEarned}
        </span>
      </div>

      <div>
        <div className={styles.makePaymentContainer}>
          <div className={styles.makePaymentContainer}>
            <button
              disabled={referrals?.length == 0 ? true : false}
              style={{
                cursor: referrals?.length == 0 ? "not-allowed" : "pointer",
              }}
              className={styles.paymentButton}
              onClick={handleButtonClick}
            >
              Make Payment
            </button>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Profile;
