import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./ReferralsComponent.css";
import { Pie, Doughnut } from "react-chartjs-2";
import { api, setAuthToken } from "../../../Api";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { isLoader, setUser } from "../../../Redux/actions/userActions";
import { notifyError, notifySuccess } from "../../util/Toaster/Toaster";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NotFound from "../../../commonComponents/Notfound";
import { getElementById } from "../../../helpers/productList";

import TeamsReferrals from "./TeamsReferrals";

const USER_STATUS = ["Pending", "Qualified", "Converted", "Rejected"];

const STATUS = [
  "Pending",
  "Not Qualified",
  "Qualified",
  "Requirement Gathering",
  "Interview Scheduled",
  "Closed by Client",
  "Finalized",
  "Candidate Onboarded",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#49bfa1 !important",
    textTransform: "capitalize",
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: "#92C5FD",
  },
}))(Button);

const ReferralsComponent = () => {
  const [referrals, setReferrals] = useState([]);
  const [allReferrals, setAllReferrals] = useState([]);
  const [amounts, setAmounts] = useState({});
  const [submitHidden, setSubmitHidden] = useState(false);
  const [isUpdatingAmount, setIsUpdatingAmount] = useState(false);
  // const [isFetchingCommission, setIsFetchingCommission] = useState(false)
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [sortingOption, setSortingOption] = useState("all");
  const [timesort, setTimeSort] = useState("yearly");
  const [transectionModal, setTransectionModal] = useState(false);
  const [statusChange, setStatusChange] = useState({});
  const [updatingReferralId, setUpdatingReferralId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [readMoreModal, setReadMoreModal] = useState({
    state: false,
    value: "",
  });
  const [searchParams, setSearchParams] = useState({
    referent_name: "",
    full_name: "",
    status: "",
    sortBy: "DESC",
  });
  const [pagination, setPagination] = useState({
    page_no: 1,
    data_per_page: 10,
    filter_by_status: null,
  });
  const [totalData, setTotalData] = useState({
    total_count: 0,
    total_page: 1,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchChartData();
    fetchReferrals();
  }, [pagination, dispatch, searchParams.sortBy]);

  useEffect(() => {
    if (searchParams.referent_name == "") {
      fetchChartData();
      fetchReferrals();
    }
  }, [searchParams.referent_name]);

  const fetchReferrals = async () => {
    try {
      // dispatch(isLoader(true))
      setIsLoading(true);
      setSubmitHidden(false);
      setUpdatingReferralId(null);
      const token = localStorage.getItem("loggedUserToken"); // Get the token from local storage
      const { page_no, data_per_page, filter_by_status } = pagination;
      const params = {
        data_per_page,
        page_no,
        filter_by_status,
        ...searchParams,
      };
      setAuthToken(token); // Set the token in the request headers
      const response = await api.post("/referrals", params);
      const { referrals, totalCount } = response.data;
      const total_page = Math.ceil(parseInt(totalCount) / data_per_page);
      if (total_page === 1 && pagination.page_no === 1) {
        setDisablePrev(true);
        setDisableNext(true);
      }
      if (total_page > 1 && pagination.page_no === 1) {
        setDisableNext(false);
        setDisablePrev(true);
      }
      setReferrals(referrals);
      setTotalData({
        total_data: parseInt(totalCount),
        total_page,
      });
      // notifySuccess(response.data.message)
      // sortReferrals();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    }
    // dispatch(isLoader(false))
    setIsLoading(false);
  };

  const Reset = async () => {
    setSearchParams({ referent_name: "", sortBy: "DESC" });
    setPagination({
      page_no: 1,
      data_per_page: 10,
      filter_by_status: null,
    });
  };

  const fetchChartData = async () => {
    try {
      const token = localStorage.getItem("loggedUserToken"); // Get the token from local storage
      setAuthToken(token); // Set the token in the request headers
      const response = await api.post("/referrals/chart-all");
      setAllReferrals(response.data.referrals);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    }
  };

  const handleNextPage = async () => {
    const currentPage = pagination.page_no;
    const total_page = totalData.total_page;

    setDisablePrev(false);
    setPagination((prevState) => ({
      ...prevState,
      page_no: currentPage + 1,
    }));

    if (currentPage === total_page - 1) {
      setDisableNext(true);
    }
  };

  const handlePrevPage = async () => {
    const currentPage = pagination.page_no;
    setDisableNext(false);
    setPagination((prevState) => ({
      ...prevState,
      page_no: currentPage - 1,
    }));

    if (currentPage - 1 === 1) {
      setDisablePrev(true);
    }
  };

  const handleStatusChangeModal = async (event, referralId, userid) => {
    const leadStatus = event.target.value;
    setStatusChange({
      leadStatus,
      referralId,
      userid,
    });

    setTransectionModal(true);
  };

  const handleLeadStatusChange = async () => {
    let { leadStatus, referralId, userid } = statusChange;
    setTransectionModal(false);

    if (leadStatus == "1") {
      leadStatus = parseInt(leadStatus, 10);
    }

    const updatedReferrals = referrals.map((referral) => {
      if (referral.id === referralId) {
        return {
          ...referral,
          lead_status: leadStatus,
        };
      }
      return referral;
    });

    setReferrals(updatedReferrals);
    if (leadStatus !== "2") {
      try {
        // setIsFetchingCommission(true);
        const token = localStorage.getItem("loggedUserToken"); // Get the token from local storage
        if (token) {
          setAuthToken(token); // Set the token in the request headers
          const response = await api.post(
            `/rule-engine/datatobiz-affiliate-rule`,
            {
              ticket_size: 0,
              lead_status: leadStatus,
              lead_id: referralId,
              user_id: userid,
            }
          );
          notifySuccess(response.data.message);
        }
        fetchReferrals();
      } catch (error) {
        if (error.response.status === 401) {
          notifyError(error.response.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          notifyError(error.response.data.message);
        } else {
          notifyError("Something went wrong");
        }
      }
      // finally {
      //   setIsFetchingCommission(false); // Set isFetchingCommission back to false
      // }
    }
    setStatusChange({});
  };

  const handleAmountChange = (event, referralId) => {
    const newAmount = event.target.value;
    const updatedAmounts = { ...amounts };
    updatedAmounts[referralId] = newAmount;
    setAmounts(updatedAmounts);
    setUpdatingReferralId(referralId); // Set the updating referral ID
  };

  const updateAmount = async (referralId, leadStatus, userid) => {
    try {
      const newAmount = amounts[referralId];
      if (newAmount < 0) {
        return notifyError("Amount should be greater than 0.");
      }
      // setIsFetchingCommission(true);
      setIsUpdatingAmount(true); // Set isUpdatingAmount to true
      const response = await api.post(`/rule-engine/datatobiz-affiliate-rule`, {
        ticket_size: newAmount,
        lead_status: leadStatus,
        lead_id: referralId,
        user_id: userid,
      });
      notifySuccess(response.data.message);
      setSubmitHidden(true);
      fetchReferrals();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    } finally {
      setIsUpdatingAmount(false);
      // setIsFetchingCommission(false); // Set isFetchingCommission back to false
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return;
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "IST",
    });
  };

  const countReferralIDFrequency = () => {
    const referralIDCounts = {};

    allReferrals &&
      allReferrals?.forEach((referral) => {
        const { referral_id } = referral;
        if (referralIDCounts[referral_id]) {
          referralIDCounts[referral_id] += 1;
        } else {
          referralIDCounts[referral_id] = 1;
        }
      });

    const sortedFrequencies = Object.entries(referralIDCounts)
      .sort((a, b) => b[1] - a[1])
      .reduce(
        (acc, [id, frequency]) => {
          acc.labels.push(id);
          acc.data.push(frequency);
          return acc;
        },
        { labels: [], data: [] }
      );

    return sortedFrequencies;
  };

  const referralIDData = countReferralIDFrequency();

  const filterReferrals = (option) => {
    setPagination((prevState) => ({
      ...prevState,
      page_no: 1,
      filter_by_status: option,
    }));
    setSortingOption(option);
  };

  const filteredReferrals =
    sortingOption === "all"
      ? referrals
      : referrals?.filter(
          (referral) => referral?.lead_status.toString() === sortingOption
        );

  // Generate data and options for the chart
  const chartData = {
    labels: ["Pending", "Qualified", "Converted", "Rejected"],
    datasets: [
      {
        label: "Lead Status",
        data: [
          allReferrals?.filter((referral) => referral.lead_status === 0)
            ?.length,
          allReferrals?.filter((referral) => referral.lead_status === 1)
            ?.length,
          allReferrals?.filter((referral) => referral.lead_status === 2)
            ?.length,
          allReferrals?.filter((referral) => referral.lead_status === 3)
            ?.length,
        ],
        backgroundColor: [
          "rgb(54, 162, 235)", // Blue for Pending
          "rgb(255, 205, 86)", // Yellow for Qualified
          "	rgb(50,205,50)", // Green for Converted
          "rgb(255, 99, 132)", // Red for Rejected
        ],
        borderColor: [
          "rgba(255, 165, 0, 1)",
          "rgba(255, 255, 0, 1)",
          "rgba(0, 128, 0, 1)",
          "rgba(255, 0, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    elements: {
      arc: {
        backgroundColor: "green", // Custom canvas background color
        hoverOffset: 15, // Hover offset in pixels
      },
    },
  };
  const chartData1 = {
    labels: referralIDData.labels,
    datasets: [
      {
        label: "Referral ID Frequency",
        data: referralIDData.data,
        fill: false,
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const options1 = {
    indexAxis: "y",
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        precision: 0, // Set precision to 0 to display whole numbers
      },
    },
  };

  const totalAmount = referrals.reduce(
    (total, referral) => total + referral.amount,
    0
  );
  const topFiveAmounts = referrals
    .sort((a, b) => b.amount - a.amount)
    .slice(0, 5)
    .map((referral) => referral.amount);

  const backgroundColors = [
    "rgba(255, 99, 132, 0.7)",
    "rgba(23, 162, 184, 0.7)",
    "rgba(54, 162, 235, 0.7)",
    "rgba(128, 0, 128, 0.7)",
    "rgba(255, 206, 86, 0.7)",
  ];
  function getMonthlyData(dataArray) {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    return dataArray
      ?.filter((item) => {
        const itemDate = new Date(item.updatedAt);
        return (
          itemDate.getFullYear() === year && itemDate.getMonth() === month - 1
        );
      })
      ?.map((referral) => referral.amount || 0);
  }

  const readMore = (str) => {
    let maxLength = 18;
    if (str?.length > maxLength) {
      return (
        <>
          {str.slice(0, maxLength)}...
          <span
            style={{
              color: "blue",
              fontStyle: "italic",
              textDecoration: "underline",
            }}
          >
            {" "}
            read more.
          </span>
        </>
      );
    }
    return str;
  };

  function getYearlyData(dataArray) {
    const today = new Date();
    const year = today.getFullYear();
    return dataArray
      ?.filter((item) => {
        const itemDate = new Date(item.updatedAt);
        return itemDate.getFullYear() === year;
      })
      ?.map((referral) => referral.amount || 0);
  }

  const chartData2 = {
    labels: allReferrals?.map((referral) => referral.user_id).slice(0, 5), // Display only the first 5 user IDs on the x-axis
    datasets: [
      {
        label: "Amount",
        data:
          timesort === "yearly"
            ? getYearlyData(allReferrals)
            : getMonthlyData(allReferrals),
        backgroundColor: backgroundColors.slice(0, allReferrals?.length),
        borderColor: "rgba(23, 162, 184, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options2 = {
    indexAxis: "x",
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        precision: 0,
        title: {
          display: true,
          text: "User ID",
        },
      },
      y: {
        beginAtZero: true,
        precision: 0,
        title: {
          display: true,
          text: "Amount",
        },
        ticks: {
          stepSize: 5,
        },
      },
    },
  };

  const handleRowClick = (referralId) => {
    setSelectedRow(referralId);
  };

  return (
    // <Carousel>
    <div style={{ margin: "0 1.2rem", height: "90vh" }}>
      <div>
        <h2
          style={{
            textAlign: "center",
            margin: "40px 0px 10px 0px",
            fontWeight: "bold",
          }}
        >
          ADMIN DASHBOARD
        </h2>
        {/* <div className="chart-container"> */}
        {/* <div className="chart-item">
            <h3 style={{ textAlign: "center" }}>Lead Status</h3>
            <Doughnut data={chartData} options={options} />
          </div>
          <div className="chart-item">
            <h3 style={{ textAlign: "center" }}>Top Referrals</h3>
            <div className="chart-center">
              <Bar data={chartData1} options={options1} />
            </div>
          </div> */}
        {/* <div className="chart-item">
            <h3>Total Amount</h3>
            <div className="chart-center">
              <Bar data={chartData2} options={options2} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <label htmlFor="leadStatus">Filter by : </label>{" "}
                <select
                  id="leadStatus"
                  value={timesort}
                  onChange={(e) => setTimeSort(e.target.value)}
                >
                  <option value="yearly">Yearly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
            </div>
          </div> */}
        {/* </div> */}

        {/* <p className="show-ref-p">Show all Referals</p> */}
      </div>
      {isLoading && (
        <div className="loading-container">
          <div className="loading"></div>
          <p>Loading...</p>
        </div>
      )}
      {!isLoading && (
        <div className="">
          <h2 style={{ textAlign: "center", margin: "24px 0px" }}>Referrals</h2>
          <div className="sort-container">
            {/* <label htmlFor="leadStatus">Filter by Lead Status:</label>
            <select
              id="leadStatus"
              // value={sortingOption}
              value={searchParams.status}
              onChange={(e) => {
                // filterReferrals(e.target.value)
                setSearchParams({
                  ...searchParams,
                  status: e.target.value,
                });
              }}
            >
              <option value={""}>All</option>
              {STATUS?.map((itm, i) => (
                <option key={i} value={itm}>
                  {itm}
                </option>
              ))}
            </select> */}

            <div className="sort-container" style={{ marginBottom: 0 }}>
              <label htmlFor="leadStatus">Sort by:</label>
              <select
                id="leadStatus"
                value={searchParams.sortBy}
                onChange={(e) =>
                  setSearchParams({ ...searchParams, sortBy: e.target.value })
                }
              >
                <option value="DESC">Latest</option>
                <option value="ASC">Oldest</option>
              </select>
            </div>

            <div style={{ display: "flex", gap: "4px" }}>
              <input
                style={{
                  padding: "2px 4px",
                  borderRadius: "4px",
                  outline: "none",
                }}
                value={searchParams.referent_name}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    fetchReferrals();
                  }
                }}
                placeholder="Referent Name"
                name="referent_name"
                onChange={(e) =>
                  setSearchParams({
                    ...searchParams,
                    referent_name: e.target.value,
                    full_name: e.target.value,
                  })
                }
              />
              <ColorButton onClick={() => fetchReferrals()}>Search</ColorButton>
              <ColorButton onClick={() => Reset()}>Reset</ColorButton>
            </div>
          </div>

          {/* <TeamsReferrals searchParams={searchParams} /> */}
          {true && (
            <div className="">
              {referrals?.length == 0 ? (
                <NotFound />
              ) : (
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Referral Email</th>
                      <th>Referral Name</th>

                      <th>Referral ID</th>

                      <th>Lead Status</th>
                      <th>Ticket Size($)</th>
                      <th>Commision($) </th>

                      <th>Referral URL</th>
                      {/* <th>Product Name</th> */}
                      {/* <th>Payment Status</th> */}
                      <th>Phone</th>
                      {/* <th>Message</th> */}
                      {/* <th>Payment On</th> */}
                      {/* <th>Created At</th>
                  <th>Updated At</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {referrals.map((referral, i) => (
                      <tr
                        key={referral.id}
                        className={
                          selectedRow === referral.id ? "active-row" : ""
                        }
                        onClick={() => handleRowClick(referral.id)}
                      >
                        <td>{referral.id}</td>
                        {/* <td>
                        {Number(pagination.page_no) == 1
                          ? i + 1
                          : Number(`${pagination.page_no - 1}` + "0") + (i + 1)}
                      </td> */}
                        <td>{referral.referent_email}</td>
                        <td>{referral.referent_name}</td>

                        <td>
                          {/* <Link
                        to={`/profile/${referral.referral_id}`}
                        style={{ textDecoration: "none", color: "red" }}
                      > */}
                          {referral.referral_id}
                          {/* </Link> */}
                        </td>

                        <td>
                          <select
                            value={referral.lead_status}
                            onChange={(event) =>
                              handleStatusChangeModal(
                                event,
                                referral.id,
                                referral.user_id
                              )
                            }
                            disabled={referral.product_id === 1}
                          >
                            <option value="0">Pending</option>
                            <option value="1">Qualified</option>
                            <option value="2">Converted</option>
                            <option value="3">Rejected</option>
                          </select>
                        </td>
                        <td>
                          {isUpdatingAmount &&
                          updatingReferralId === referral.id ? (
                            "Updating amount..."
                          ) : referral.lead_status === "2" ? (
                            <div className="amount-input">
                              <input
                                type="number"
                                min="0"
                                placeholder="Converted Amount"
                                style={{ paddingLeft: "10px" }}
                                value={amounts[referral.id] || ""}
                                onChange={(event) =>
                                  handleAmountChange(event, referral.id)
                                }
                              />
                              {!submitHidden && (
                                <button
                                  onClick={() =>
                                    updateAmount(
                                      referral.id,
                                      2,
                                      referral.user_id
                                    )
                                  }
                                  style={{
                                    padding: "2px",
                                    color: "white",
                                    backgroundColor: "green",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                  }}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          ) : Number(referral?.amount) !== 0 ? (
                            "$" + referral.amount
                          ) : (
                            "-"
                          )}
                        </td>

                        {/* <td>
                      {referral.product_id === 1
                        ? referral.commission_converted
                        : referral.commission_qualfied.toString() +
                          " + " +
                          referral.commission_converted.toString()}
                    </td> */}
                        <td>
                          {referral.product_id === 1
                            ? referral.commission_converted == 0
                              ? "-"
                              : "$ " + referral.commission_converted
                            : (
                                Number(referral.commission_qualfied) +
                                Number(referral.commission_converted)
                              ).toFixed(2) == 0
                            ? "-"
                            : "$ " +
                              (
                                Number(referral.commission_qualfied) +
                                Number(referral.commission_converted)
                              ).toFixed(2)}
                        </td>

                        <td>{referral.referral_url}</td>
                        {/* <td>{getElementById(referral.product_id)?.name}</td> */}
                        {/* <td>{referral.payment_status}</td> */}
                        <td>{referral.phone}</td>
                        {/* <td>
                          <p
                            style={{
                              width: "200px",
                              whiteSpace: "pre-wrap",
                              cursor: "pointer",
                              fontWeight: 500,
                            }}
                            onClick={() =>
                              setReadMoreModal({
                                state: true,
                                value: referral.message,
                              })
                            }
                          >
                            {readMore(referral.message)}
                          </p>
                        </td> */}
                        {/* <td>{formatDate(referral.payment_on)}</td> */}
                        {/* <td>{formatDate(referral.createdAt)}</td>
                    <td>{formatDate(referral.updatedAt)}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      )}
      <br />
      <div className="pagination-handler d-flex justify-content-end align-items-center">
        <button
          className="btn btn-outline-primary prepai-btn"
          onClick={handlePrevPage}
          disabled={disablePrev}
        >
          {"<"}
        </button>
        <span style={{ padding: "0px 10px" }}>{pagination.page_no}</span>
        <button
          className="btn btn-outline-primary prepai-btn"
          onClick={handleNextPage}
          disabled={disableNext}
        >
          {">"}
        </button>
      </div>
      <br />
      <br />

      <Modal
        open={readMoreModal.state}
        onClose={() => {
          setReadMoreModal({ ...readMoreModal, state: false });
        }}
        aria-labelledby="modal-modal-readmore"
        aria-describedby="modal-modal-readmore"
      >
        <Box style={{ borderRadius: "7px" }} sx={style}>
          <Typography
            style={{ marginBottom: "2rem", fontSize: "1rem" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {readMoreModal?.value}
          </Typography>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                setReadMoreModal({ ...readMoreModal, state: false });
              }}
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={transectionModal}
        onClose={() => {
          setTransectionModal(false);
          setStatusChange({});
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ borderRadius: "7px" }} sx={style}>
          <Typography
            style={{ marginBottom: "2rem" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to change the lead status to{" "}
            {USER_STATUS[statusChange.leadStatus]} ?
          </Typography>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                setTransectionModal(false);
                setStatusChange({});
              }}
            >
              Cancel
            </button>

            <button
               style={{background:'#49bfa1', color:'#fff', borderColor:'#49bfa1'}} className="btn btn-primary"
              onClick={handleLeadStatusChange}
            >
              Proceed
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ReferralsComponent;
