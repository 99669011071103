import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { api, setAuthToken } from "../../../Api";
import { useDispatch } from "react-redux";
import classes from "../Transections/Transections.module.css";
import { notifyError, notifySuccess } from "../../util/Toaster/Toaster";
import { isLoader, setUser } from "../../../Redux/actions/userActions";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { validateEmail } from "../../util/Helper";
import Notfound from "../../../commonComponents/Notfound";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#49bfa1 !important",
    textTransform: "capitalize",
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: "#92C5FD",
  },
}))(Button);

const UpcomingTransections = (props) => {
  const [transections, setTransections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState("all"); // Default value for userType
  const [loggedUser, setLoggedUser] = useState(props.userData);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [transectionModal, setTransectionModal] = useState(false);
  const [allEmail, setAllEmail] = useState(null);
  const [emailSearchLoader, setEmailSearchLoader] = useState(false);
  const [user_email, setUserEmail] = useState("");
  const [filter, setFilter] = useState({
    email: "",
    startDate: "",
    endDate: "",
    sortBy: "ASC",
  });
  const [pagination, setPagination] = useState({
    page_no: 1,
    data_per_page: 10,
    filter_by_type: null,
  });
  const [totalData, setTotalData] = useState({
    total_count: 0,
    total_page: 1,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterValidation = () => {
    const { startDate, endDate } = filter;
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start > end) {
      notifyError("Please enter a valid date range.");
      return true;
    }
    return false;
  };

  const fetchTransaction = async (search) => {
    if (search) {
      const validationResult = filterValidation();
      if (validationResult) {
        return;
      }
    }
    const { page_no, data_per_page, filter_by_type } = pagination;
    const params = {
      data_per_page,
      page_no,
      filter_by_type,
      ...filter,
    };
    setIsLoading(true);
    api
      .post("/payments/upcoming-transaction/product-services/all", params)
      .then((response) => {
        // notifySuccess("Data fetched successfully!")
        // const sortedUsers = response.data.users.sort((a, b) => {
        //   return new Date(b.createdAt) - new Date(a.createdAt);
        // });

        const { transections, totalCount } = response.data;

        const total_page = Math.ceil(parseInt(totalCount) / data_per_page);
        if (total_page === 1 && pagination.page_no === 1) {
          setDisablePrev(true);
          setDisableNext(true);
        }
        if (total_page > 1 && pagination.page_no === 1) {
          setDisableNext(false);
          setDisablePrev(true);
        }
        setTransections(transections);
        setTotalData({
          total_data: parseInt(totalCount),
          total_page,
        });
        // notifySuccess(response.data.message);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          notifyError(error.response.data.message);
        } else {
          notifyError("Something went wrong!");
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("loggedUserToken");
    setAuthToken(token);
    fetchTransaction();
  }, [pagination, filter.sortBy]);

  const Reset = () => {
    setPagination({
      page_no: 1,
      data_per_page: 10,
      filter_by_type: null,
    });
    setFilter({ email: "", startData: "", endData: "", sortBy: "DESC" });
  };

  const handleNextPage = async () => {
    const currentPage = pagination.page_no;
    const total_page = totalData.total_page;

    setDisablePrev(false);
    setPagination((prevState) => ({
      ...prevState,
      page_no: currentPage + 1,
    }));

    if (currentPage === total_page - 1) {
      setDisableNext(true);
    }
  };

  const handlePrevPage = async () => {
    const currentPage = pagination.page_no;
    setDisableNext(false);
    setPagination((prevState) => ({
      ...prevState,
      page_no: currentPage - 1,
    }));

    if (currentPage - 1 === 1) {
      setDisablePrev(true);
    }
  };

  const serchAllEmails = async () => {
    api
      .post(`/users/email-search`, { email: user_email })
      .then((response) => {
        const { users } = response.data;
        if (user_email !== "") {
          setAllEmail(users);
        } else {
          setAllEmail([]);
        }
        // notifySuccess(response.data.message);
        setEmailSearchLoader(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          notifyError(error.response.data.message);
        } else {
          notifyError("Something went wrong!");
        }
        setEmailSearchLoader(false);
      });
  };

  const handleEmailChange = (event) => {
    let value = event.target.value;
    if (value === "") {
      setEmailSearchLoader(false);
      setAllEmail(null);
    } else {
      setEmailSearchLoader(true);
    }
    setUserEmail(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (user_email) {
        serchAllEmails();
      }
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [user_email]);

  const handleTransection = async () => {
    if (!validateEmail(user_email)) {
      notifyError("please enter a valid email");
      return;
    }
    try {
      dispatch(isLoader(true));
      const resp = await api.post("/payments/getUserId", { email: user_email });
      if (resp.status === 200) {
        dispatch(isLoader(false));
        navigate(`/profile/${resp.data.user.id}?tab=1`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    } finally {
      dispatch(isLoader(false));
    }
  };

  //   const handleUserTypeChange = (event, userId) => {
  //     const newUserType = event.target.value;
  //     const token = localStorage.getItem("loggedUserToken");

  //     setAuthToken(token);
  //     api
  //       .put(`/user/${userId}`, { userType: newUserType })
  //       .then((response) => {
  //         // User type updated successfully
  //         const updatedTransections = transections.map((user) => {
  //           if (user.userId === userId) {
  //             return { ...user, userType: newUserType };
  //           }
  //           return user;
  //         });
  //         setTransections(updatedUsers);
  //         notifySuccess(response.data.message);
  //         const updatedLoggedUser = { ...loggedUser, userType: newUserType };
  //         dispatch({ type: "SET_LOGGED_USER", payload: updatedLoggedUser });
  //       })
  //       .catch((error) => {
  //         if (error.response.status === 401) {
  //           notifyError(error.response.message);
  //           dispatch(setUser(null));
  //           navigate("/login");
  //         } else if (error.response.status === 404) {
  //           notifyError(error.response.message);
  //         }
  //         notifyError("Something went wrong, please try agian");
  //       });
  //   };

  //   const handleUserTypeFilter = (event) => {
  //     const value = event.target.value;
  //     setPagination((prevState) => ({
  //       ...prevState,
  //       page_no: 1,
  //       filter_by_type: value,
  //     }));
  //     setUserType(value);
  //   };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "IST",
    });
  };

  return (
    <div className="affiliate" style={{ margin: "1.2rem 2.2rem" }}>
      {isLoading && (
        <div className={classes["loading-container"]}>
          <div className={classes.loading}></div>
          <p>Loading...</p>
        </div>
      )}
      {!isLoading && (
        <>
          <div className={classes["table-options"]}>
            <h2 className="mb-0 w-100 text-center">Upcoming Transactions</h2>
          </div>
          <br />
          <div className={classes["filter-container"]}>
            <div className="sort-container" style={{ marginBottom: 0 }}>
              <label htmlFor="leadStatus">Sort by:</label>
              <select
                id="leadStatus"
                value={filter.sortBy}
                onChange={(e) => setFilter({ sortBy: e.target.value })}
              >
                <option value="DESC">Latest</option>
                <option value="ASC">Oldest</option>
              </select>
            </div>

            <TextField
              id="date"
              label="Start Date"
              type="date"
              value={filter.startDate}
              // variant="outlined"
              className={classes.textField}
              onChange={(e) =>
                setFilter({ ...filter, startDate: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="End Date"
              value={filter.endDate}
              type="date"
              // variant="outlined"
              className={classes.textField}
              onChange={(e) =>
                setFilter({ ...filter, endDate: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <ColorButton onClick={() => fetchTransaction(true)}>
              Search
            </ColorButton>
            <ColorButton onClick={() => Reset()}>Reset</ColorButton>
          </div>
          {transections?.length !== 0 ? (
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Ticket Size</th>
                  <th>Commission</th>
                  {/* <th>Payment Status</th> */}
                  <th>Due Date</th>
                  {/* <th>Reserved Column</th> */}
                </tr>
              </thead>
              <tbody>
                {transections?.map((transection, i) => (
                  <tr key={transection.id}>
                    <td>{transection.referent_name}</td>
                    <td>
                      <Link style={{color:'#49bfa1'}} to={`/profile/payments/${transection.user_id}`}>
                        {transection["referent_email"]}{" "}
                      </Link>
                    </td>
                    <td>{transection.amount}</td>
                    <td>{transection.commission_converted}</td>
                    {/* <td>{"Un Paid"}</td> */}
                    <td>{formatDate(transection.due_date)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div style={{ marginTop: "2rem" }}>
              <Notfound />
            </div>
          )}

          <div className="pagination-handler d-flex justify-content-end align-items-center">
            <button
              className="btn btn-outline-primary prepai-btn" 
              onClick={handlePrevPage}
              disabled={disablePrev}
            >
              {"<"}
            </button>
            <span style={{ padding: "0px 10px" }}>{pagination.page_no}</span>
            <button
              className="btn btn-outline-primary prepai-btn"
              onClick={handleNextPage}
              disabled={disableNext}
            >
              {">"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UpcomingTransections;
