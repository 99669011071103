import React, { useState } from "react";
import Button from "../../util/Button/Button";
import "./InfoPage.css";
import { Link } from "react-router-dom";
import VideoPopup from "./../../common/videoPopup";

const InfoPage = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <section>
      <div className="container-cls" style={{ background: "#fff" }}>
        <div className="imageContainer-cls">
          <img
            // height={"420"}
            alt="prep-ai-hero"
            className="cls-img-hero-banner"
            style={{ marginRight: 0 }}
            src="img/hero.jpg"
            onClick={openPopup}
          ></img>
        </div>
        <div className="contentContainer-cls">
          <div className="infoPage">
            {/* <h3
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              Affiliate Program for Data Engineering Team Augmentation Services
            </h3> */}
            <span className="head-text">
              Turn your online presence into a lifetime revenue stream.
            </span>
            <h4
              // data-aos="fade-left"
              // data-aos-duration="1000"
              // data-aos-delay="400"
              className="desc"
              style={{ fontSize: "15px" }}
            >
              Join the PrepAI affiliate program and experience the benefits of
              passive earnings while endorsing our dynamic AI assessment
              generation platform.
            </h4>
          </div>

          <div
            data-aos="fade-down"
            data-aos-duration="500"
            style={{ paddingBottom: "1rem" }}
          >
            <Button></Button>
          </div>

          <div
            className="login-at-end"
            data-aos="fade-up"
            data-aos-duration="500"
            style={{
              color: "#888888",
              paddingBottom: "3rem",
              marginLeft: "7px",
              fontSize: "14px",
            }}
          >
            Already an affiliate?{" "}
            <Link to="/login" style={{ fontWeight: 500, color: "#49bfa1" }}>
              Login
            </Link>
          </div>
        </div>
      </div>

      {/* <div>
        {isPopupOpen && (
          <VideoPopup
            videoUrl="videos/affiliate_program_team_augmentation_datatobiz.mp4"
            isOpen={isPopupOpen}
            onRequestClose={closePopup}
          />
        )}
      </div> */}
    </section>
  );
};

export default InfoPage;
