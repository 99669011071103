import React from "react";
import styles from "./Footer.module.css"; // Assuming you have a Footer.module.css file for styling
import FooterHead from "./FooterHead/FooterHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faPinterestP,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faInbox,
  faMailBulk,
  faMailReply,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Footer = ({ hideCTA = false }) => {
  return (
    <>
      {!hideCTA && <FooterHead></FooterHead>}

      <footer id="main-footer" className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.footerWidgets}>
            <div className={styles.footerWidget}>
              <div className={styles.mediaImage} style={{ textAlign: "left" }}>
                <a
                  href="https://affiliate.datatobiz.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ height: "50px" }}
                    src={`${process.env.REACT_APP_PROJECT_URL}/img/prepai-logo.png`}
                    className={styles.imageFooter}
                    alt="blacklogo"
                    decoding="async"
                    loading="lazy"
                  />
                </a>
              </div>
              <div className={styles.customHTML}>
                <div className={styles.textWidget}>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "15px",
                      textAlign: "left",
                      width: "650px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    PrepAI offers a smart & easy test creation process backed by
                    advanced AI algorithms. It helps you create quality exams,
                    quizzes, and tests using an easy-to-use dashboard. With
                    multiple input options; Create, conduct, and practice tests
                    online with PrepAI.
                  </p>
                </div>
              </div>
              <div className={styles.customHTML}>
                <div
                  className={styles.textWidget}
                  style={{ textAlign: "left" }}
                >
                  {/* <a
                    className={styles.footerBtnLink}
                    href="http://datatobiz.com/"
                    target="_blank"
                  >
                    <span className={styles.footerBtn}>Visit Main Website</span>
                  </a> */}
                  <button className={styles.btnjoin}>
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.prepai.io/"
                    >
                      Visit Main Website
                    </a>
                  </button>
                </div>
              </div>
              <div className={styles.customHTML}>
                <img
                  style={{ height: "50px" }}
                  src={`${process.env.REACT_APP_PROJECT_URL}/img/producthunt.svg`}
                  className={styles.imageFooter}
                  alt="blacklogo"
                  decoding="async"
                  loading="lazy"
                />
                <img
                  style={{ height: "45px" }}
                  src={`${process.env.REACT_APP_PROJECT_URL}/img/capterra.svg`}
                  className={styles.imageFooter}
                  alt="blacklogo"
                  decoding="async"
                  loading="lazy"
                />
              </div>
              <div className={styles.customHTML}>
                <a
                  style={{ color: "#13BFB3" }}
                  className={styles.menuItem}
                  href="https://www.prepai.io/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                &nbsp;&nbsp;
                <a
                  style={{ color: "#13BFB3" }}
                  className={styles.menuItem}
                  href="/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Affiliate Terms and Conditions
                </a>{" "}
                &nbsp;&nbsp;
                {/* <a
                  style={{ color: "#13BFB3" }}
                  className={styles.menuItem}
                  href="https://prepai.io/cancellation-and-refund-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cancellation and Refund Policy
                </a>{" "}
                &nbsp;&nbsp;
                <a
                  style={{ color: "#13BFB3" }}
                  className={styles.menuItem}
                  href="https://prepai.io/fulfillment-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fulfillment Policy
                </a> */}
              </div>
            </div>
            {/* <div className={styles.footerWidget}>
              <div className={styles.navMenu}>
                <h4 className={styles.title}>Solutions</h4>
                <div className={styles.menuSolutionsContainer}>
                  <ul className={styles.menu}>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/artificial-intelligence/"
                        target="_blank"
                      >
                        Artificial Intelligence
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/data-analytics/"
                        target="_blank"
                      >
                        Data Analytics
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/data-warehousing/"
                        target="_blank"
                      >
                        Data Warehousing
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/natural-language-processing/"
                        target="_blank"
                      >
                        Natural Language Processing
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/vision-analytics/"
                        target="_blank"
                      >
                        Vision Analytics
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/big-data/"
                        target="_blank"
                      >
                        Big Data
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/business-intelligence/"
                        target="_blank"
                      >
                        Business Intelligence
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/power-bi-services-usa/"
                        target="_blank"
                      >
                        Power BI
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/hire-ai-engineers/"
                        target="_blank"
                      >
                        AI Staff Augmentation
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/hire-tableau-engineers/"
                        target="_blank"
                      >
                        Hire Tableau Engineers
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/hire-azure-data-engineers/"
                        target="_blank"
                      >
                        Hire Azure Data Engineers
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/automated-digital-lending-solutions/"
                        target="_blank"
                      >
                        Automated Digital Lending Solution
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.footerWidget}>
              <div className={styles.navMenu}>
                <h4 className={styles.title}>Explore</h4>
                <div className={styles.menuExploreContainer}>
                  <ul className={styles.menu}>
                    <li className={styles.menuItem}>
                      <a href="https://www.datatobiz.com/blog/" target="_blank">
                        Knowledge Base
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/case-studies/"
                        target="_blank"
                      >
                        Case Studies
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/industries/"
                        target="_blank"
                      >
                        Industries
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a href="https://careers.datatobiz.com/" target="_blank">
                        Careers
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/about/"
                        target="_blank"
                      >
                        About
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/contact/"
                        target="_blank"
                      >
                        Contact
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a href="/terms-and-conditions/" target="_blank">
                        Terms and Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className={styles.footerWidget}>
              <div className={styles.navMenu}>
                <h4 className={styles.title}>Contact Info</h4>
                <div className={styles.menuProductContainer}>
                  <ul className={styles.menu}>
                    <li className={styles.menuItem}>
                      <FontAwesomeIcon
                        size="lg"
                        color="rgb(73, 191, 161)"
                        icon={faPhone}
                      />{" "}
                      &nbsp;
                      <a href="tel:+917009935623">+91 7009935623</a>
                    </li>
                    <li className={styles.menuItem}>
                      <FontAwesomeIcon
                        size="lg"
                        color="rgb(73, 191, 161)"
                        icon={faPhone}
                      />{" "}
                      &nbsp;
                      <a href="tel:+1 (628) 251-1377">+1 (628) 251-1377</a>
                    </li>
                    <li className={styles.menuItem}>
                      <a href="mailto:prepai@datatobiz.com">
                        <FontAwesomeIcon
                          size="lg"
                          color="rgb(73, 191, 161)"
                          icon={faEnvelope}
                        />{" "}
                        &nbsp; prepai@datatobiz.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.customHTML}>
                <h4 className={styles.title}>Follow Us </h4>
                <div className={styles.textWidget}>
                  <div className={styles.socialMenuFooter}>
                    <ul className={styles.socialUl}>
                      <li>
                        <a
                          style={{ color: "#49bfa1" }}
                          href="https://twitter.com/PrepAIGenerator"
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            icon={faTwitter}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ color: "#49bfa1" }}
                          href="https://www.instagram.com/prepai.io/"
                          target="_blank"
                          rel="noopener"
                        >
                          <FontAwesomeIcon
                            icon={faInstagram}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ color: "#49bfa1" }}
                          href="https://www.linkedin.com/showcase/prep-ai/about/"
                          target="_blank"
                          rel="noopener"
                        >
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                      {/* <li>
                        <a
                          style={{ color: "#49bfa1" }}
                          href="https://www.facebook.com/datatobiz"
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            icon={faFacebook}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ color: "#49bfa1" }}
                          href="https://pin.it/1CjPZUo"
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            icon={faPinterest}
                            className={styles.icon}
                          />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className={styles.footerBottom}>
          <div className={styles.container}>
            <div id="footer-info">
              © 2023 DataToBiz
              <span className={styles.trademark}>
                <sup>TM</sup>
              </span>{" "}
              All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
