import React from "react";
import ReactDOM from "react-dom";
// import "bootstrap/dist/css/bootstrap.css";
// import "./Partners.css"
import styles from "./Partners.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faHandHoldingDollar,
  faArrowTrendUp,
  faTrophy,
  faPeopleCarryBox,
  faLightbulb,
  faSignal,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

const Partners = () => {
  return (
    <div className={styles.works}>
      <div className={styles.worksContainer}>
        <h1
          className={styles.heading}
          data-aos="fade-right"
          data-aos-duration="500"
        >
          Why join the PrepAI affiliate program?
        </h1>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "20px",
            textAlign: "center",
            whiteSpace: "pre-wrap",
            marginBottom: "20px",
            color:'#fff'
          }}
        >
          {" "}
          Join the PrepAI affiliate program to boost your online earnings with
          high commissions
          <br /> and make a significant impact by promoting cutting-edge AI
          technology.
        </p>
        <div
          className={styles.wrapper}
          data-aos="fade-right"
          data-aos-duration="500"
        >
          <div className={styles.card1}>
            <div>
             <img style={{height:'60px', width:'60px'}} src="/img/Icons/Dedicated Support.png" alt="icon"/>
            </div>
            <div>
              <h2 style={{ marginTop:4, fontSize: "16px" }}>Unlock Profit Potential</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              As a PrepAI affiliate, you gain the potential to significantly
              boost your income. By promoting the AI-based online test
              generator, you can earn commissions starting from 10% to 35% based
              on the pricing model for each successful referral.
            </div>
          </div>
          <div className={styles.card2}>
          <div>
             <img style={{height:'60px', width:'60px'}} src="/img/Icons/Demand.png" alt="icon"/>
            </div>
            <div>
              <h2 style={{ marginTop:4, fontSize: "16px" }}>High-Demand Product</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              PrepAI caters to the ever-growing demand for effective assessment
              tools in education. Your promotion for this high-demand product
              positions you to attract a broad customer base including
              educational institutions, B2B customers, tutors, teachers, content
              creators, etc.
            </div>
          </div>
          <div className={styles.card4}>
          <div>
             <img style={{height:'60px', width:'60px'}} src="/img/Icons/Seamless Integration.png" alt="icon"/>
            </div>
            <div>
              <h2 style={{ marginTop:4, fontSize: "16px" }}>Seamless Integration</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Integrating PrepAI's solutions into your online presence is made
              simple with a user-friendly dashboard provided to affiliates.
              Whether you have a website or an online platform, you can
              effortlessly incorporate the program to start reaping the
              financial benefits.
            </div>
          </div>
          <div className={styles.card3}>
          <div>
             <img style={{height:'60px', width:'60px'}} src="/img/Icons/Profit.png" alt="icon"/>
            </div>
            <div>
              <h2 style={{ marginTop:4,fontSize: "16px" }}>Dedicated Support</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              We are committed to ensuring your success as an affiliate. You can
              count on dedicated support, including training, resources, and
              responsive assistance to help you make the most of this
              opportunity and foster your growth in the program.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
