import React from "react";
import styles from "./prepaiCTA.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
export const PrepaiCTA = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.fold}>
      <div className={styles.textContainer}>
        <h1>No Referrals Yet?</h1>
        <p>
          It's time to turn that around! Introduce others to the advanced AI
          assessment generator and start earning rewards. Enter your referral
          details to get started today.{" "}
        </p>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <button
          onClick={() => {
            navigate("/products");
          }}
          className={styles.openBtn}
        >
          Start Refering &nbsp;{" "}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </button>
      </div>
    </div>
  );
};
