import React, { useEffect, useState } from "react";
import { api, setAuthToken } from "../../../Api";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../util/Toaster/Toaster";
import { setUser } from "../../../Redux/actions/userActions";

import {
  StyledTab,
  StyledTabs,
} from "../../../pageComponets/profile/TabStyles";
import TabPanel from "../../../pageComponets/profile/TabPanel";

import "./User.css";

import TransactionTab from "../Profile/TransactionTab";
import Notfound from "../../../commonComponents/Notfound";
import ReferralsGivenTable from "./ReferralsGiven";
import ReferralsGivenTeamTable from "./ReferralsGivenTeam";
import { Noreferral } from "./Noreferral";
import { PrepaiCTA } from "../../common/prepaiCTA";
const UserProfile = () => {
  const [referrals, setReferrals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const [totalAmount, setTotalAmount] = useState("");
  const [payments, setPayments] = useState(null);
  const [paidAmount, setPaidAmount] = useState(0);
  const [remain, setRemain] = useState(0);
  const [paginationReferrals, setPaginationReferrals] = useState({
    page_no: 0,
    data_per_page: 2,
    total_pages: 0,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const calculateTotalAmount = (payments) => {
    let total = 0;
    let newRemain = 0;
    let totalTeamReferralCommision = 0;
    payments.forEach((payment) => {
      if (
        payment?.payment_status == 0 &&
        !isNaN(payment?.commission_converted)
      ) {
        newRemain += parseFloat(payment?.commission_converted);
      }
      const amount_qualified = parseFloat(payment.commission_qualfied);
      const amount_converted = parseFloat(payment.commission_converted);
      if (!isNaN(amount_converted)) {
        total += amount_converted;
      }
      if (!isNaN(amount_qualified)) {
        total += amount_qualified;
      }
      if (payment.commission) {
        const total_team_referral = parseFloat(payment.commission);
        totalTeamReferralCommision += total_team_referral;
      }
    });
    setRemain(newRemain);
    setTotalAmount(total);
  };

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await api.get(`/payments/${loggedUser.userId}`);
        const paymentData = response.data.payments;
        // notifySuccess(response.data.message);
        if (Array.isArray(paymentData)) {
          if (paymentData.length === 0) {
            setPayments([]);
            setPaidAmount(0.0); // Set the total amount to 0 if there are no payments
          } else {
            setPayments(paymentData);
            calculateTotalPaidAmount(paymentData);
          }
        } else if (typeof paymentData === "object") {
          setPayments([paymentData]);
          calculateTotalPaidAmount([paymentData]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          notifyError(error.response.data.message);
        } else {
          notifyError("Something went wrong");
        }
      }
    };

    // Function to calculate the total amount
    const calculateTotalPaidAmount = (payments) => {
      let total = 0;

      payments.forEach((payment) => {
        const amount = parseFloat(payment.amount);
        if (!isNaN(amount)) {
          total += amount;
        }
      });

      setPaidAmount(total);
    };

    fetchPayments();
  }, []);

  useEffect(() => {
    setAuthToken(localStorage.getItem("loggedUserToken"));
    api
      .get(`/referrals/${loggedUser.userId}`)
      .then((response) => {
        setReferrals(response.data);
        calculateTotalAmount(response.data.referrals);
        setIsLoading(false);
        // notifySuccess(response.data.message);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response && error.response.status === 404) {
          // notifyError("User has not given any referrals till now.");
        } else {
          notifyError("Something went wrong");
        }
        setIsLoading(false);
      });

    // api
    //   .post(`/referrals/user/team-referrals`, {
    //     referral_id: localStorage.getItem("referral_id"),
    //   })
    //   .then((response) => {
    //     setReferrals(response.data);
    //     localStorage.setItem('total_referrals',response?.data?.referrals?.length)
    //     calculateTotalAmount(response.data.referrals);
    //     setIsLoading(false);
    //     // notifySuccess(response.data.message);
    //   })
    //   .catch((error) => {
    //     if (error.response && error.response.status === 401) {
    //       notifyError(error.response.message);
    //       dispatch(setUser(null));
    //       navigate("/login");
    //     } else if (error.response && error.response.status === 404) {
    //       // notifyError("User has not given any referrals till now.");
    //     } else {
    //       notifyError("Something went wrong");
    //     }
    //     setIsLoading(false);
    //   });
  }, [paginationReferrals.page_no]);

  return (
    <>
      <div className="up-container">
        <h1 className="page-title">Affiliate Dashboard</h1>
        <StyledTabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label=""
        >
          <StyledTab label="No. of Referrals" />
          <StyledTab label="Transaction Details" />
        </StyledTabs>
      </div>
      <TabPanel value={value} index={0}>
        <div
          className="container-block"
          style={{ margin: "0 1.2rem", width: "auto", height: "100%" }}
        >
          {isLoading ? (
            <div className="loading" style={{ marginTop: "1rem" }}></div>
          ) : (
            <div className="table-container table_container1">
              {/* <h2 className="heading">Referrals Given</h2> */}
              {!referrals ||
              !referrals?.referrals ||
              referrals?.referrals?.length === 0 ? (
                <>
                  <Notfound text="You haven't provided any referral." />
                  <br />
                  <br />
                  <PrepaiCTA />
                </>
              ) : (
                <ReferralsGivenTable
                  referrals={referrals}
                  style={{ margin: "20px 0", boxShadow: "" }}
                />
                // <ReferralsGivenTeamTable
                //   referrals={referrals}
                //   style={{ margin: "20px 0", boxShadow: "" }}
                // />
              )}
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="" style={{ margin: "0 1.2rem", width: "auto" }}>
          <div className="table-container-c table_container1 mb-10">
            <TransactionTab transections={payments} />
          </div>
        </div>
        <div className="btm-table">
          <div className="table-bottom">
            <div className="bottom-lines">
              <p className="amount-desc">
                Total Commission Earned:{" "}
                <span className="amount">${paidAmount.toFixed(2)}</span>
              </p>
            </div>
            <div className="bottom-lines">
              <p className="amount-desc">
                Remaining Balance:{" "}
                <span className="amount">${remain?.toFixed(2)}</span>
              </p>
            </div>
          </div>
        </div>
      </TabPanel>
      <br />
      <br />
    </>
  );
};

export default UserProfile;
