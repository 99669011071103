import { useState } from "react";
import styles from "./ClipBoard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faLink } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const ClipBoard = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator?.clipboard?.writeText(props.link);
    setIsCopied(true);
    // alert("Link is copied to clipboard!");
    Swal.fire({
      icon: "success",
      title: "Link copied to clipboard!",
      confirmButtonText: "Okay, got it",
      confirmButtonColor: "#161A34",
    });
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className={styles.clipboard} onClick={() => handleCopy()} >
      <span>{props?.link.slice(8)}</span>
      <FontAwesomeIcon size="lg" icon={faLink}/>
    </div>
  );
};

export default ClipBoard;
