import { getElementById } from "../../../helpers/productList";

const ReferralsGivenTable = ({ referrals, style }) => {
  const getLeadStatusText = (leadStatus) => {
    switch (leadStatus) {
      case 0:
        return "Pending";
      case 1:
        return "Qualified";
      case 2:
        return "Converted";
      case 3:
        return "Rejected";
      default:
        return "";
    }
  };
  return (
    <table className="styled-table" style={{ ...style, boxShadow: "none" }}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          {/* <th>Product/Service</th> */}
          <th>Ticket Size($)</th>
          <th>Commission Earned($)</th>
          <th>Lead Status</th>
        </tr>
      </thead>
      <tbody>
        {referrals?.referrals?.map((referral) => (
          <tr key={referral.id}>
            <td>{referral.referent_name}</td>
            <td>{referral.referent_email}</td>
            {/* <td>{getElementById(referral.product_id)?.name}</td> */}
            <td>
              {Number(referral?.amount) !== 0 ? "$" + referral.amount : "-"}
            </td>
            <td>
              {referral.product_id === 1
                ? referral.commission_converted == 0
                  ? "-"
                  : "$ " + referral.commission_converted
                : (
                    Number(referral.commission_qualfied) +
                    Number(referral.commission_converted)
                  ).toFixed(2) == 0
                ? "-"
                : "$ " +
                  (
                    Number(referral.commission_qualfied) +
                    Number(referral.commission_converted)
                  ).toFixed(2)}
            </td>
            {/* <td>
        {referral.product_id === 1
          ? referral.commission_converted
          : referral.commission_qualfied.toString() +
            " + " +
            referral.commission_converted.toString()}
      </td> */}
            <td>{getLeadStatusText(referral.lead_status)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ReferralsGivenTable;
