import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Header from "./components/util/Header/Header";
import LoginForm from "./components/util/LoginForm/LoginForm";
import SignupForm from "./components/util/SignUpForm/SignupForm";
import AffiliateWidget from "./components/HomePage/AffiliateWidget/AffiliateWidget";
import ServiceCard from "./components/HomePage/Services/ServiceCard/ServiceCard";
import ReferralPage from "./components/HomePage/ReferralsComponent/ReferralPage";
import Payouts from "./components/payouts";
import HomePage from "./components/HomePage/HomePage";
import Profile from "./components/HomePage/Profile/Profile";
import PaymentPage from "./components/PaymentPage/PaymentPage";
import Unsubscribe from "./components/HomePage/UnsubscribePage/Unsubscribe";
import UserProfile from "./components/HomePage/Profile/UserProfile";
import Footer from "./components/util/Footer/Footer";
import Main from "./components/Main";
import Affiliate from "./components/HomePage/AffiliateUser/Affiliate";
import { api, setAuthToken } from "./Api";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { useSelector, useDispatch } from "react-redux";
import { isLoader, setUser } from "./Redux/actions/userActions";
import CustomLoader from "./components/util/Loader/Loader";
import { notifyError } from "./components/util/Toaster/Toaster";

import "./App.css";
import Transections from "./components/HomePage/Transections/Transections";

import { fetchUserDetails } from "./api/user";
import TermsCondition from "./components/HomePage/TermsCondition/TermsCondition";
import CC from "./components/CC/CC";
import UpcomingTransections from "./components/HomePage/UpcomingTransaction/UpcomingTransaction";
import FourOhFour from "./components/404";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [loading, setLoading] = useState(true); // State for loading status
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const isLoading = useSelector((state) => state.user.isLoading);
  const dispatch = useDispatch();
  const [hideCall, setHideCall] = useState(false);


  useEffect(() => {
    const storedToken = localStorage.getItem("loggedUserToken");
    if (storedToken) {
      dispatch(isLoader(true));
      setAuthToken(storedToken);
      fetchUserDetails(dispatch);
    } else {
      dispatch(isLoader(false));
      setLoading(false); // Set loading to false if no stored token is found
    }

    if (
      window.innerWidth <= 768 ||
      window.innerWidth <= 800 ||
      window.innerWidth <= 720
    ) {
      document.body.style.zoom = "75%";
    }
  }, [dispatch]);

  // if (loading) {
  //   return (
  //     <div className="loading-container">
  //       <div className="loading"></div>
  //       <p>Verifying credentials</p>
  //     </div>
  //   ); // Display loading message
  // }
  console.log(loggedUser)
  return (
    <Provider store={store}>
      <Toaster
        position="top-right"
        containerClassName="hot-toaster-container"
      />
      <Router>
        {isLoading ? (
          <CustomLoader isLoading={isLoading} />
        ) : (
          <>
            <Header user={loggedUser} />
            <main style={{ margin: 0, marginBottom:'-3rem' }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    loggedUser ? <HomePage userData={loggedUser} /> : <Main />
                  }
                />
                <Route
                  path="/cc-emails"
                  element={loggedUser ? <CC userData={loggedUser} /> : <Main />}
                />
                <Route path="/login" element={<LoginForm />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsCondition />}
                />
                <Route path="/register" element={<SignupForm />} />
                <Route
                  path="/unsubscribe-email"
                  element={
                    loggedUser ? (
                      <Unsubscribe userData={loggedUser} />
                    ) : (
                      <Main />
                    )
                  }
                />
                <Route
                  path="/home"
                  element={
                    loggedUser ? <HomePage userData={loggedUser} /> : <Main />
                  }
                />
                <Route
                  path="/services"
                  element={
                    loggedUser ? (
                      <ServiceCard userData={loggedUser.referralId} />
                    ) : (
                      <Main />
                    )
                  }
                />
                <Route
                  path="/referrals"
                  element={
                    loggedUser ? (
                      <ReferralPage userData={loggedUser.referralId} />
                    ) : (
                      <Main />
                    )
                  }
                />
                <Route
                  path="/payouts"
                  element={
                    loggedUser ? (
                      <Payouts userData={loggedUser.referralId} />
                    ) : (
                      <Main />
                    )
                  }
                />
                <Route
                  path="/products"
                  element={
                    loggedUser ? (
                      <AffiliateWidget userData={loggedUser.referralId} />
                    ) : (
                      <Main />
                    )
                  }
                />
                <Route
                  path="/profile/"
                  element={
                    loggedUser ? (
                      <UserProfile userData={loggedUser} />
                    ) : (
                      <Main />
                    )
                  }
                />
                <Route
                  path="/profile/:userId"
                  element={
                    loggedUser?.userType == "Admin" ? (
                      <Profile userData={loggedUser} />
                    ) : (
                      <Navigate to="/404" />
                    )
                  }
                />
                <Route
                  path="/affiliate"
                  element={
                    loggedUser?.userType == "Admin" ? (
                      <Affiliate userData={loggedUser} />
                    ) : (
                      <Navigate to="/404?from=affiliate" replace/>
                    )
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    loggedUser?.userType == "Admin" ? (
                      <Transections userData={loggedUser} />
                    ) : (
                      <Navigate to="/404?from=transactions" />
                    )
                  }
                />
                <Route
                  path="/upcoming-transactions"
                  element={
                    loggedUser?.userType == "Admin" ? (
                      <UpcomingTransections userData={loggedUser} />
                    ) : (
                      <Navigate to="/404?from=upcoming-transactions" />
                    )
                  }
                />
                <Route
                  path="/profile/payments/:userId"
                  element={
                    loggedUser?.userType == "Admin" ? (
                      <PaymentPage userData={loggedUser} />
                    ) : (
                      <Navigate to="/404" />
                    )
                  }
                />
                <Route path="*" element={<FourOhFour />} />
              </Routes>
            </main>
            {/* <Footer /> */}
          </>
        )}
      </Router>
      {(!sessionStorage.getItem("hideCall") && !hideCall) && (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            borderTop: "2px solid #49bfa1",
            paddingBottom: "7px",
            textAlign: "center",
            position: "fixed",
            left: 0,
            bottom: 0,
            width: "100%",
          }}
        >
          <FontAwesomeIcon
            onClick={() => {
              setHideCall(true)
              sessionStorage.setItem("hideCall", "true");
            }}
            icon={faX}
            style={{
              cursor: "pointer",
              position: "fixed",
              left: 15,
              bottom: 37,
            }}
          />
          <span style={{ margin: 0, padding: 0 }}>
            Want to get the most out of PrepAI Affiliate?
          </span>{" "}
          &nbsp;
          <button
            onClick={() => {
              window.location.href =
                "https://calendly.com/prepai/demo-brief-for-prepai";
            }}
            style={{
              borderRadius: "100px",
              marginTop: "7px",
              padding: "10px",
              backgroundColor: "#49bfa1",
              color: "#fff",
              fontSize: "14px",
              fontWeight: 500,
              cursor: "pointer",
              outline: "none",
              border: "0px",
            }}
          >
            Schedule a call
          </button>
        </div>
      )}
    </Provider>
  );
}

export default App;
