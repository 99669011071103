import React from "react";
import "./termscondition.css";
import { Link } from "react-router-dom";
import Footer from "../../util/Footer/Footer";
import PrepaiTable from "../../common/prepaiTable";
const TermsCondition = () => {
  return (
    <>
      <div className="main-tc">
        <div className="container-tc">
          <p className="para" style={{ textAlign: "center" }}></p>
          <h2 className="title">Terms and Conditions</h2>
          <p className="para" style={{ textAlign: "left" }}>
            Welcome to the PrepAI Affiliate Program!
          </p>
          <p className="para">
            By entering{" "}
            <a href="https://affiliate.prepai.io/" style={{ color: "#49bfa1" }}>
              https://affiliate.prepai.io/
            </a>
            , you are confirming your agreement to comply with our Website Terms
            and Conditions of Use. We stress the significance of compliance with
            local laws while using our platform. If these terms are not
            agreeable to you, we kindly request that you refrain from accessing
            the site.
            <br></br>
            <br></br>
            It's crucial to recognize that the materials found on this website
            are shielded by copyright and trademark laws. We expect users to
            respect and adhere to these legal standards to preserve the
            integrity of our intellectual property. Your cooperation is greatly
            valued, ensuring a secure and mutually respectful online
            environment.
          </p>
          <h3 className="title2">Use a License</h3>
          <p className="para">
            By participating in the PrepAI Affiliate Program, you agree to the
            following terms and conditions. Please read the following carefully
            before joining the program.
            {/* <p className="para-list">
              <div className="circle"></div>modify or copy the materials;
            </p>
            <div className="para-list">
              <div className="circle"></div>
              use the materials for any commercial purpose or for any public
              display;
            </div>
            <div className="para-list">
              <div className="circle"></div>
              attempt to reverse engineer any software contained on DataToBiz’s
              Website;
            </div>
            <div className="para-list">
              <div className="circle"></div>
              remove any copyright or other proprietary notations from the
              materials; or
            </div>
            <div className="para-list">
              <div className="circle"></div>
              transferring the materials to another person or “mirroring” the
              materials on any other server.
            </div>
            <p className="para-list">
              This will let DataToBiz terminate upon violations of any of these
              restrictions. Upon termination, your viewing right will also be
              terminated and you should destroy any downloaded materials in your
              possession whether it is printed or electronic format.
            </p> */}
          </p>
          <h3 className="title2">License Grant</h3>
          <p className="para">
            PrepAI grants you a limited, non-exclusive, non-transferable license
            to access and use the materials provided on the PrepAI Affiliate
            Website for personal, non-commercial transitory viewing only. This
            license is not a transfer of title, and all rights not expressly
            granted are reserved by PrepAI.
          </p>

          <h3 className="title2">Restrictions</h3>
          <p className="para">
            Under this license, you are expressly prohibited from:
            <br></br>
            <br></br>
            <p className="para-list">
              <div className="circle"></div>Modifying or Copying: You may not
              modify or copy the materials available on the PrepAI Affiliate
              Website.
            </p>
            <div className="para-list">
              <div className="circle"></div>
              Commercial Use: The materials are for personal, non-commercial use
              only. You may not use the materials for any commercial purpose or
              public display.
            </div>
            <div className="para-list">
              <div className="circle"></div>
              Reverse Engineering: You may not attempt to reverse engineer any
              software contained on PrepAI's Website.
            </div>
            <div className="para-list">
              <div className="circle"></div>
              Copyright Notices: You may not remove any copyright or other
              proprietary notations from the materials.
            </div>
            <br></br>
            <p className="para-list">
              Transfer and Mirroring: You may not transfer the materials to
              another person or "mirror" the materials on any other server.
            </p>
          </p>

          <h3 className="title2">Termination</h3>
          <p className="para">
            This license will automatically terminate if you violate any of
            these restrictions. Upon termination, your viewing right will also
            be terminated, and you must promptly destroy any downloaded
            materials in your possession, whether in printed or electronic
            format.
          </p>

          <h3 className="title2">Consequences of Violation</h3>
          <p className="para">
            In the event of a violation of these restrictions, PrepAI reserves
            the right to terminate your participation in the Affiliate Program.
            This termination may be immediate and without prior notice.
          </p>

          <h3 className="title2">Disclaimer</h3>
          <p className="para">
            PrepAI's affiliate program operates on a "as is" basis for all
            materials on its website, without any expressed or implied
            warranties. The program makes no claims about the accuracy or
            reliability of the materials on the website or linked sites. This
            straightforward approach ensures transparency and sets clear
            expectations for affiliates, establishing a professional and honest
            foundation for collaboration.
          </p>
          {/* <p className="para">
            Read the full Disclaimer{" "}
            <Link
              style={{ color: "#49bfa1" }}
              to="https://www.datatobiz.com/disclaimer-for-datatobiz/"
            >
              here.
            </Link>
          </p> */}

          <h3 className="title2">Cookies</h3>
          <p className="para">
            Our platform utilizes cookies, and by entering the PrepAI Affiliate
            Platform, you consent to their use by PrepAI’s Privacy Policy.
          </p>
          <p className="para">
            Cookies, a common practice on interactive websites, help retrieve
            user details during each visit. They enhance specific areas'
            functionality on our site, streamlining the experience for visitors.
            Additionally, some of our affiliate and advertising partners may
            employ cookies for similar purposes. This approach ensures seamless
            navigation and aligns with our commitment to user-friendly
            interactions while acknowledging the collaborative use of cookies by
            external partners.
          </p>

          <h3 className="title2">
            Affiliate Commissions: Product and Services
          </h3>
          <span>
            To support you in your affiliate journey, we've designed a simple
            yet rewarding commission structure to recognize your efforts and
            contributions. Check out the breakdown below to understand how you
            can earn commissions based on your referrals and conversions.
          </span>
          <PrepaiTable newBoxShadow={true} />
          <p className="para">
            By joining the PrepAI Affiliate Program, you enter into an agreement
            wherein you stand to earn commissions for each new paid subscriber
            or referred B2B customer. Our fair approach ensures that the more
            referrals you bring in, the more you stand to earn as an affiliate.
            Your commission rates are tied to our monthly/annual, teams, and
            solo pricing plans, guaranteeing fair compensation for each new
            paying customer you successfully bring to PrepAI. This commitment
            reflects our dedication to transparency and fairness within our
            affiliate partnership.
          </p>

          <h3 className="title2">Limitations</h3>
          <p className="para">
            By participating in the affiliate program, affiliates acknowledge
            and agree to these limitations as an integral part of the terms and
            conditions governing their partnership with PrepAI.
          </p>

          <h3 className="title2">Fraudulent Activities</h3>
          <p className="para">
            Affiliates are strictly prohibited from engaging in any form of
            fraudulent activity, including but not limited to click
            manipulation, false representation, or any deceptive practices that
            may compromise the integrity of the affiliate program.
          </p>

          <h3 className="title2">Geographical Restrictions:</h3>
          <p className="para">
            Affiliates must adhere to any geographical limitations specified by
            PrepAI for the affiliate program. Violation of these restrictions
            may result in the termination of the affiliate relationship.
          </p>
          <h3 className="title2">Compliance Monitoring:</h3>
          <p className="para">
            PrepAI reserves the right to monitor affiliate activities to ensure
            compliance with the terms and conditions. Any affiliate found in
            violation may face penalties, including termination from the
            program.
          </p>

          <h3 className="title2">Ethical Promotion:</h3>
          <p className="para">
            Affiliates are expected to promote PrepAI truthfully and ethically.
            Misleading or inappropriate promotional tactics are not permitted
            and may lead to the immediate termination of the affiliate
            partnership.
          </p>

          <h3 className="title2">Revisions and Errata</h3>
          <p className="para">
            PrepAI reserves the right to make revisions and corrections to any
            content, materials, or information provided in the affiliate program
            documentation. While we strive to ensure accuracy, occasional errors
            or omissions may occur. Affiliates are encouraged to check for
            updates regularly and notify PrepAI of any identified inaccuracies.
          </p>

          <h3 className="title2">Links</h3>
          <p className="para">
            Affiliates may utilize designated affiliate links provided by PrepAI
            for promotional purposes. These links are unique to each affiliate
            and are essential for accurate tracking of referrals. Affiliates
            must ensure the correct implementation of these links in their
            promotional materials. <br></br> <br></br> PrepAI is not responsible
            for any inaccuracies or lack of commission resulting from
            incorrectly formatted or non-functional affiliate links. Affiliates
            are advised to contact PrepAI support promptly should they encounter
            any issues with their affiliate links.
          </p>

          <h3 className="title2">Privacy Policy</h3>

          <p className="para">
            Your privacy is of utmost importance to us, and we encourage you to
            familiarize yourself with our Privacy Policy. This document outlines
            how we collect, use, and safeguard your data as you engage with
            PrepAI's services. Your trust is valuable to us, and we are
            committed to maintaining the highest standards of privacy and
            security. For detailed information on how we handle your data,
            please read the PrepAI Privacy Policy{" "}
            <Link
              style={{ color: "#49bfa1" }}
              to="https://www.prepai.io/privacy-policy/"
            >
              here.
            </Link>
          </p>
          <h3 className="title2">Site Terms of Use Modifications</h3>
          <p className="para">
            PrepAI reserves the right to modify the Terms of Use for its website
            without prior notice. By continuing to use this website, you
            acknowledge and agree to be bound by the latest version of these
            Terms and Conditions of Use. It is advisable to periodically review
            these terms to stay informed about any updates. For any inquiries or
            clarification regarding these terms, please contact our support team
            at{" "}
            <a href="mailto:prepai@datatobiz.com" style={{ color: "#49bfa1" }}>
              prepai@datatobiz.com
            </a>
          </p>

          <h3 className="title2">Governing Law</h3>
          <p className="para">
            Any claim or legal matter related to PrepAI's website will be
            governed by local laws, irrespective of any conflicting legal
            provisions. We prioritize adherence to local regulations to ensure a
            fair and consistent application of the law. Your use of this website
            implies your acceptance of this governing law clause.
            <br></br>
            <br></br>
            If you have any concerns or questions regarding the governing law or
            our terms of use, please reach out to our support team at{" "}
            <a href="mailto:prepai@datatobiz.com" style={{ color: "#49bfa1" }}>
              prepai@datatobiz.com
            </a>{" "}
            for assistance.
          </p>
          <h3 className="title2">Dispute and Settlement</h3>
          <p className="para">
            In the event of any financial disputes or related concerns, PrepAI
            (under DataToBiz Pvt Ltd.) holds the ultimate authority for
            resolving matters. Our commitment is to ensure a fair and
            transparent resolution process that favors the well-being of our
            affiliate partners. Any necessary adjustments to rectify the
            situation in favor of the business will be immediately established
            by PrepAI. Rest assured, our objective is to handle disputes with
            efficiency and professionalism, developing a collaborative and
            trustworthy affiliate relationship overall.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer hideCTA={true} />
    </>
  );
};

export default TermsCondition;
