import "./PaymentPage.css";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { api, setAuthToken } from "../../Api";
import { useLocation } from "react-router-dom";
import { notifyError, notifySuccess } from "../util/Toaster/Toaster";
import { setUser } from "../../Redux/actions/userActions";

import { validate } from "../util/Validation";
import GoBack from "../../commonComponents/Goback";
import moment from "moment-timezone";

const PaymentPage = () => {
  const location = useLocation();
  // const totalCommission = location?.state?.totalCommission;
  const [paymentAmount, setPaymentAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [amountDue, setAmountDue] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const { userId } = useParams();
  const user = userId;
  const [isEditing, setIsEditing] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [user_Id, setUserId] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [country, setCountry] = useState("");
  const [payments, setPayments] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const [checkedForPayment, setCheckedForPayement] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [errorFetchingBankDetails, setErrorFetchingBankDetails] =
    useState(false);
  const [transactionId, setTransactionId] = useState(""); // Added transaction ID state
  const [totalCommission, setTotalCommission] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchBankDetails = async () => {
    try {
      setAuthToken(localStorage.getItem("loggedUserToken"));
      const userResponse = await api.get(`/user/${user}`);
      const { fullName, email, id } = userResponse.data;
      setFullName(fullName);
      setEmail(email);
      setUserId(id);

      const response = await api.get(`/bank/${userId}`);
      const {
        bank_name,
        bank_code,
        branch_name,
        account_number,
        country,
        swift_code,
      } = response.data.bankDetails;
      if (account_number === 0) {
        setErrorFetchingBankDetails(true);
        notifyError("Bank details not found!");
      }
      setCountry(country);
      setAccountNumber(account_number);
      setSwiftCode(swift_code ?? "");
      setIfscCode(bank_code);
      setBankName(bank_name);
      setBankBranch(branch_name);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
      setErrorFetchingBankDetails(true);
    }
  };
  useEffect(() => {
    fetchPayments();
    fetchBankDetails();
  }, []);

  const fetchPayments = async () => {
    try {
      const response = await api.get(`/payments/${userId}`);
      setReferrals(response?.data?.upcoming_transaction_ps_data);
      const paymentData = response.data.payments;
      let earned = 0;
      response?.data?.upcoming_transaction_ps_data?.forEach((pay) => {
        earned += Number(pay?.commission_converted);
      });
      setTotalCommission(earned);

      if (Array.isArray(paymentData)) {
        if (paymentData.length === 0) {
          setPayments([]);
          setPaidAmount(0);
        } else {
          setPayments(paymentData);
          calculateTotalPaidAmount(paymentData);
        }
      } else if (typeof paymentData === "object") {
        setPayments([paymentData]);
        calculateTotalPaidAmount([paymentData]);
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    if (checkedForPayment.length > 0) {
      let amount = 0;
      checkedForPayment?.map((itm) => {
        amount += Number(itm?.commission);
      });
      setPaymentAmount(amount);
    } else {
      setPaymentAmount(0);
    }
  }, [checkedForPayment]);

  const calculateTotalPaidAmount = (payments) => {
    let total = 0;

    payments.forEach((payment) => {
      const amount = parseFloat(payment.amount);
      if (!isNaN(amount)) {
        total += amount;
      }
    });
    setPaidAmount(total);
  };

  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleTransactionIdChange = (e) => {
    const value = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    if (alphanumericRegex.test(value) || value === "") {
      setTransactionId(value);
    }
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    if (Number(totalCommission) - Number(paidAmount) < Number(paymentAmount)) {
      return notifyError("Cannot make payment, insufficient balance.");
    }
    try {
      if (
        !validate({
          "transaction id": transactionId,
          "payment amount": paymentAmount,
        })
      ) {
        return;
      }
      setLoading(true);
      // if (!transactionId) {
      //   alert("Please enter a transaction ID.");
      //   return;
      // }
      const paymentData = {
        user_id: userId,
        transaction_id: transactionId,
        amount: paymentAmount,
        referrals_ps: checkedForPayment,
        paid_by: loggedUser.fullName,
        status: 1,
        transaction_time: new Date(),
      };

      const response = await api.post("/payments", paymentData);
      const createdPayment = response.data.payment;
      notifySuccess(
        `Payment made successfully! Transaction ID: ${createdPayment.transaction_id}`
      );
      fetchPayments();
      setTransactionId("");
      setPaymentAmount("");
      setPaymentMethod("");
    } catch (error) {
      setLoading(false);
      console.error("Error making payment:", error);
      notifyError("Payment failed. Please try again.");
      // alert();
    }
    setLoading(false);
  };

  const generateTransactionId = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let transactionId = "txn";
    for (let i = 0; i < 9; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      transactionId += characters.charAt(randomIndex);
    }
    return transactionId;
  };

  return (
    <>
      <div className="container">
        <GoBack />
      </div>
      <div className="container">
        <div className="main">
          <div style={{ maxWidth: "100%" }} className="card custom-height">
            <h1>Payment Details</h1>
            <div className="profile-field">
              <label htmlFor="fullName">Paying To: </label>
              <span>{fullName}</span>
            </div>

            <div className="profile-field">
              <label>Payee Email:</label>
              <span>{email}</span>
            </div>
            <div className="profile-field">
              <label>Payee User ID:</label>
              <span>{userId}</span>
            </div>
          </div>
          <br />
        </div>
        <div style={{ maxWidth: "100%" }} className="card">
          {/* Bank Details */}
          <h1>Bank Details</h1>
          <div className="profile-field">
            {/* Account Number */}
            <label>Account Number:</label>
            <span>{accountNumber}</span>
          </div>
          {ifscCode !== "" && (
            <div className="profile-field">
              <label>IFSC Code:</label>
              <span>{ifscCode}</span>
            </div>
          )}
          {swiftCode !== "" && (
            <div className="profile-field">
              <label>Swift Code:</label>
              <span>{swiftCode}</span>
            </div>
          )}
          <div className="profile-field">
            {/* Bank Name */}
            <label>Bank Name:</label>
            <span>{bankName}</span>
          </div>
          <div className="profile-field">
            {/* Bank Address */}
            <label>Bank Address:</label>
            <span>{bankBranch}</span>
          </div>
          <div className="profile-field">
            {/* Country */}
            <label>Country:</label>
            <span>{country}</span>
          </div>
        </div>
      </div>
      <br />

      <br />
      <div className="container">
        <div className="main">
          <div style={{ maxWidth: "100%" }} className="card mb-5">
            <table className="styled-table">
              <thead>
                <tr>
                  <th></th>
                  {/* <th>Sr No.</th> */}
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Email</th>
                  {/* <th>Ticket Size</th> */}
                  <th style={{ textAlign: "center" }}>Commission Earned($)</th>
                  <th style={{ textAlign: "center" }}>Referred On</th>
                  <th style={{ textAlign: "center" }}>Payment Status</th>
                  <th style={{ textAlign: "center" }}>Due Date</th>
                  <th style={{ textAlign: "center" }}>Paid On</th>
                  <th style={{ textAlign: "center" }}>Transaction ID</th>
                </tr>
              </thead>
              <tbody>
                {referrals?.map((itm, i) => {
                  if (Number(itm?.commission_converted) > 0)
                    return (
                      itm !== "" && (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              disabled={itm?.payment_status == 1 ? true : false}
                              checked={
                                itm?.payment_status == 1
                                  ? true
                                  : checkedForPayment.some(
                                      (item) => item?.id === itm?.id
                                    )
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setCheckedForPayement([
                                    ...checkedForPayment,
                                    {
                                      id: itm?.id,
                                      commission: Number(
                                        itm?.commission_converted
                                      ),
                                      payment_status: 1,
                                    },
                                  ]);
                                } else {
                                  let newArr = checkedForPayment.filter(
                                    (item) => item?.id !== itm?.id
                                  );
                                  setCheckedForPayement(newArr);
                                }
                              }}
                            />
                          </td>
                          {/* <td>{i + 1}</td> */}
                          <td>{itm?.referent_name}</td>
                          <td className="payment-email">
                            {itm?.referent_email}
                          </td>
                          {/* <td>{itm?.ticket_size}</td> */}
                          <td>{itm?.commission_converted}</td>
                          <td>
                            {moment(itm.createdAt).local().format("LL LT")}
                          </td>
                          <td>
                            {itm?.payment_status == 1 ? "Paid" : "Unpaid"}
                          </td>
                          <td>
                            {itm?.due_date
                              ? moment(itm.due_date).local().format("LL LT")
                              : "-"}
                          </td>
                          <td>
                            {itm?.payment_on
                              ? moment(itm.payment_on).local().format("LL LT")
                              : "-"}
                          </td>
                          <td>{itm?.transaction_id}</td>
                        </tr>
                      )
                    );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ maxWidth: "100%" }} className="card payment">
            <h1 style={{ textAlign: "center" }}>
              {!loading ? "Make Payment" : "Please Wait"}
            </h1>
            {errorFetchingBankDetails ? (
              <div className="error-message">
                Bank Details are not available to make payments.
              </div>
            ) : (
              <div>
                {!loading ? (
                  <form onSubmit={handlePaymentSubmit}>
                    <div className="payment-section">
                      <h3 className="payment-section-heading">Amount Due</h3>
                      {/* Display the amount due */}
                      <div className="payment-info">
                        <span className="payment-page-amount">
                          ${(totalCommission - paidAmount)?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="payment-section">
                      <h3 className="payment-section-heading">Amount Paid</h3>
                      {/* Display the amount paid */}
                      <div className="payment-info">
                        <span className="payment-page-amount">
                          ${paidAmount?.toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <div className="payment-section">
                      <div className="payment-info-input-container">
                        <label
                          className="payment-page-label"
                          htmlFor="transactionId"
                        >
                          Transaction ID
                        </label>
                        <input
                          type="text"
                          maxLength={100}
                          id="transactionId"
                          className="payment-page-input"
                          placeholder="Transaction ID"
                          value={transactionId}
                          onChange={handleTransactionIdChange}
                        />
                      </div>
                      <div className="payment-info-input-container">
                        <label
                          className="payment-page-label"
                          htmlFor="payment Amount"
                        >
                          Pay Amount($)
                        </label>
                        <input
                          min={1}
                          type="text"
                          maxLength={100}
                          id="paymentAmount"
                          placeholder="0"
                          className="payment-page-input no-arrows"
                          value={paymentAmount}
                          // onChange={handlePaymentAmountChange}
                        />
                      </div>
                      {/* <div className="payment-info">
                <div className="payment-methods">
                  <div className="payment-method">
                    <input
                      type="radio"
                      id="totalDue"
                      value="totalCommission - paidAmount"
                      checked={paymentMethod === "totalDue"}
                      onChange={handlePaymentMethodChange}
                    />
                    <label htmlFor="totalDue">Pay Total Due Amount</label>
                  </div>
                </div>
              </div> */}
                    </div>
                    <div className="payment-button-container">
                      <button
                        type="submit"
                        disabled={loading}
                        className="payment-page-button"
                      >
                        {!loading ? "Make Payment" : "Please wait"}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div className="loading"></div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPage;
