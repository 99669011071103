import React from "react";

function Footer() {
  return (
    <div className="footerBottom">
      <span style={{fontSize:'17px'}}> 
        If you have any inquiries, please feel free to contact us at{" "}
        <a href={`mailto:${process.env.REACT_APP_ADMIN_EMAIL}`}>
          {process.env.REACT_APP_ADMIN_EMAIL}
        </a>
      </span>
      <br />
      <div className="" style={{marginTop:'0.5rem'}}>
        <div id="footer-info" style={{fontSize:'14px'}}>
          © 2023 DataToBiz
          <span className="trademark">
            <sup>TM</sup>
          </span>{" "}
          All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;
