import React from "react";
import styles from "./Works.module.css";

const Works = () => {
  return (
    <div className={styles.works}>
      <div className={styles.worksContainer}>
        <h1
          className={styles.heading}
          data-aos="fade-down"
          data-aos-duration="500"
        >
          How to become a PrepAI affiliate?
        </h1>
        <p
          style={{
            marginTop:'20px',
            fontWeight: "normal",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {" "}
          Our hassle-free registration process allows you to start earning
          commissions quickly. <br />
          Join us today and navigate the path to affiliate success with ease.
        </p>
        <br />
        <div className={styles.wrapper}>
          <div
            className={styles.card1}
            data-aos="flip-left"
            data-aos-duration="500"
            data-aos-delay="400"
          >
            <div>
              <img src="img/connection-1.gif" alt="Loading" />
            </div>
            <br></br>
            <div>
              <h2>Sign-up</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Embark on your affiliate journey with ease by signing up for our
              PrepAI Affiliate Program. Begin earning substantial commissions up
              to 35%* as per our current pricing structures.
            </div>
          </div>
          <div
            className={styles.card2}
            data-aos="flip-left"
            data-aos-duration="500"
            data-aos-delay="400"
          >
            <div>
              <img
                src="img/promote-1.gif"
                alt="Loading"
                width="100"
                height="62"
              />
            </div>
            <br></br>
            <div>
              <h2>Promote</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Spread the word about our AI-driven assessment generation tool
              through your affiliate link, via blog posts, social media,
              articles, email, and other digital platforms.
            </div>
          </div>
          <div
            className={styles.card3}
            data-aos="flip-left"
            data-aos-duration="500"
            data-aos-delay="400"
          >
            <div>
              <img src="img/money-bag-1.gif" alt="Loading" width="100" height="62" />
            </div>
            <br></br>
            <div>
              <h2>Earn</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Watch your earnings grow as you successfully introduce qualified
              leads to our affiliate program. You'll be rewarded with a fixed*
              commission whenever you onboard new paid subscribers to our PrepAI
              monthly/annual plans.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
