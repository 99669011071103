import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Testimonial.css";

const Testimonial = () => {
  return (
    <div className="t-container">
      <h2
        // data-aos="fade-right"
        // data-aos-duration="1000"
        // data-aos-delay="400"
        className="heading"
      >
        Affiliate partners’ stories
      </h2>
      <h5
        // data-aos="fade-left"
        // data-aos-duration="1000"
        // data-aos-delay="400"

        style={{
          fontSize: "20px !important",
          textAlign: "center",
          marginBottom: "28px",
          color: "#ffffffe0",
          // fontSize:"1rem",
          fontWeight: "300 !important",
          letterSpacing: ".03rem",
          lineHeight: "2rem",
          listStyle: "2rem",
          marginTop: "0.25em",
          opacity: 1,
        }}
      >
        Hear what our Affiliate partners have to say about our referral program
      </h5>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
        showIndicators={false}
        // className="carousel-root"
      >
        <div className="myCarousel">
          <div className="card">
            <h3>Sameer </h3>
            <h5>
              <i>Affiliate Partner, India</i>
            </h5>
            <span style={{ fontSize: "15px" }}>
              "I was surprised by how simple it was to become a Prepai
              affiliate. The provided affiliate support made sure I had all the
              resources I needed to get started, and now I'm earning while
              helping my contacts get access to a great AI-based product. It’s
              definitely a win-win!"
            </span>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <div className="card">
              <h3>John</h3>
              <h5>
                <i>Content Freelancer, New Jersey</i>
              </h5>
              <span style={{ fontSize: "15px" }}>
                "The best thing about being a Prepai affiliate program is that
                I'm not in this alone. Their dedicated support team is always
                there to answer my questions and provide guidance. It's like
                having a mentor for my side hustle!"
              </span>
            </div>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <div className="card">
              <h3>Amelia</h3>
              <h5>
                <i>Marketing Expert, London</i>
              </h5>
              <span style={{ fontSize: "15px" }}>
                "Kudos to the prepAI team for making the commission model easy
                yet transparent. You refer, you earn – no complicated
                calculations or fine print. It's a simple way to make extra
                income by sharing a valuable product."
              </span>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Testimonial;
