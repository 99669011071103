import React from "react";
import Notfound from "../../../commonComponents/Notfound";
import moment from "moment-timezone";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    // hour: "numeric",
    // minute: "numeric",
    // timeZone: "IST",
  });
};

const UpComingTransactionTab = ({ transections, style }) => {
  if (transections == null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loading"></div>
      </div>
    );
  }
  return (
    <div style={style}>
      {transections?.length !== 0 ? (
        <table className="styled-table" style={{ boxShadow: "none" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Name</th>
              <th style={{ textAlign: "center" }}>Email</th>
              <th style={{ textAlign: "center" }}>Amount</th>
              <th style={{ textAlign: "center" }}>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {transections?.map((transection, i) => transection?.payment_status === 0 && Number(transection?.commission_converted) > 0 && (
              <tr key={transection.id} style={{ textAlign: "center" }}>
                <td>{transection.referent_name}</td>
                <td>{transection.referent_email}</td>
                <td>${transection.commission_converted}</td>
                <td>{moment(transection.due_date).local().format("LL LT")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Notfound text="No Transactions Found" />
      )}
    </div>
  );
};

export default UpComingTransactionTab;
