import { async } from "q";
import { useEffect, useState } from "react";
import { api, setAuthToken } from "../../Api";
import { notifyError, notifySuccess } from "../util/Toaster/Toaster";
import { useNavigate } from "react-router";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CC = () => {
  const [loading, setLoading] = useState(false);
  const [cc, setCc] = useState([]);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const fetchEmails = async () => {
    // setLoading(true);
    try {
      const response = await api.get(`/cc/get-cc-user/`);
      setCc(response?.data?.data);
      notifySuccess(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleAddEmail = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await api.post(`/cc/add-cc-user/`, { email });
      setEmail("");
      fetchEmails();
      notifySuccess(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (email) => {
    setLoading(true);

    try {
      const response = await api.post(`/cc/del-cc-user/`, { email });
      fetchEmails();
      notifySuccess(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-profile" style={{ padding: "2rem 1rem" }}>
      <h3>Recipients List</h3>
      {loading && <div className="loading" style={{ marginTop: "1rem" }}></div>}
      {!loading && cc[0] !== "" && cc.length > 0 && (
        <table className="styled-table">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Email</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {cc?.map((itm, i) => {
              return (
                itm !== "" && (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{itm}</td>
                    <td>
                      {" "}
                      <FontAwesomeIcon
                        onClick={() => handleDelete(itm)}
                        style={{
                          fontSize: "21px",
                          margin: 0,
                          cursor: "pointer",
                          color: "red",
                        }}
                        icon={faTrashCan}
                      />
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
      )}
      <br />
      <form onSubmit={handleAddEmail}>
        <div className="hs-form__field hs-form__field-email hs-email">
          <label
            id="email-label"
            htmlFor="email-input"
            className="hs-form__field__label"
            data-required="true"
          >
            <span>Add New Email</span>
          </label>
          <input
            id="email-input"
            className="hs-form__field__input"
            type="email"
            name="email"
            required={true}
            autoComplete="email"
            inputMode="email"
            aria-invalid="false"
            aria-required="true"
            value={email}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="hs-form__actions" style={{ marginTop: "0.7rem" }}>
          <button
            style={{ background: "#49bfa1", border: "2px solid #49bfa1" }}
            type="Submit"
            name="Submit"
            className="hs-form__actions__submit"
          >
            {loading ? <p>Loading</p> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CC;
