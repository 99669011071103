import React from "react";
import styles from "./PrepAIFoldFour.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const PrepAIFoldFour = () => {
  return (
    <div className={styles.container}>
      <br/>
      <div className={styles.textContainer}>
        <h1> About PrepAI: The Affiliate Product That Pays</h1>
      </div>
      <br />
      <br />
      <div className={styles.fold}>
        <div className={styles.textContainer} style={{ textAlign: "left" }}>
          <h1>Easy-to-use Dashboard</h1>
          <p>
            A user-friendly assessment solution with an intuitive dashboard for
            easy assessment creation. Create assessments effortlessly, and
            download question papers with ease.{" "}
          </p>
        </div>
        <img
          height={"280"}
          alt="fold-1-img"
          src="/img/Easy_to_Use_Dashboard[1].gif"
        />
      </div>
      <div className={styles.fold}>
        <img
          height={"280"}
          alt="fold-1-img"
          src="/img/PrepAI Revamp GIFSDesigns (2).gif"
        />
        <div className={styles.textContainer} style={{ textAlign: "left" }}>
          <h1>Instant Quiz Generation</h1>
          <p>
            PrepAI’s advanced AI algorithm simplifies quiz creation, allowing
            you to generate high-quality questions instantly. You can input
            various content types, including text, documents, videos, and more,
            to create a variety of question types including HOTs, aptitude, and
            other numerical questions.{" "}
          </p>
        </div>
      </div>
      <div className={styles.fold}>
        <div className={styles.textContainer} style={{ textAlign: "left" }}>
          <h1>Effortless Learning</h1>
          <p>
            With PrepAI, learning and teaching are made hassle-free. Our
            automated grading system saves time, and you can generate mock and
            practice tests effortlessly. Multiple question paper sets cater to
            diverse learning needs.
          </p>
        </div>
        <img height={"280"} alt="fold-1-img" src="/img/Auto-Grading[1].gif" />
      </div>
      <br />
      <br />
    </div>
  );
};
