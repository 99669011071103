import React, { useEffect, useState } from "react";
import styles from "./AffiliateWidget.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faArrowUpRightFromSquare,
  faCopy,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import ClipBoard from "../../util/ClipBoard/ClipBoard";
import Swal from "sweetalert2";
import PrepaiTable from "../../common/prepaiTable";
import Footer from "../../common/userFooter";
import CopyToClipboard from "react-copy-to-clipboard";
const AffiliateWidget = ({ userData }) => {
  const [uniqueId, setUniqueId] = useState("");

  useEffect(() => {
    setUniqueId(userData);
  }, [userData]);

  const handleSocialIconClick = (socialSite) => {
    switch (socialSite) {
      case "facebook":
        const facebookURL = encodeURIComponent(
          `https://app.prepai.io/register?ref=${uniqueId}`
        ); // Replace with your Facebook sharing URL
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${facebookURL}`
        );
        break;
      case "twitter":
        const twitterURL = encodeURIComponent(
          `https://app.prepai.io/register?ref=${uniqueId}`
        );
        window.open(`https://twitter.com/intent/tweet?url=${twitterURL}`);
        break;
      case "linkedin":
        const linkedinURL = encodeURIComponent(
          `https://app.prepai.io/register?ref=${uniqueId}`
        );
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${linkedinURL}`
        );
        break;
      case "whatsapp":
        const referralURL = encodeURIComponent(
          `https://app.prepai.io/register?ref=${uniqueId}`
        );
        window.open(`https://api.whatsapp.com/send?text=${referralURL}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.fold1}>
          <div className={styles.textContainer}>
            <h1 style={{ fontSize: "58px !important" }}>
              Earn with Every Referral
            </h1>
            <p
              style={{
                fontSize: "28px !important",
                fontWeight: "400",
                color: "#888",
              }}
            >
              Leverage your network to earn while introducing others to the
              capabilities of PrepAI, an advanced AI assessment solution. Just
              enter your referral details and watch your commissions grow.{" "}
            </p>
            {/* <div style={{ marginTop: "2rem" }}>
              <button
                onClick={() =>
                  window.open(`https://app.prepai.io/register?ref=${uniqueId}`)
                }
                className={styles.openBtn}
              >
                Open your affiliate link &nbsp;{" "}
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </button>
              &nbsp; &nbsp;
              <button
                className={styles.copyBtn}
                onClick={() => {
                  navigator.clipboard.writeText = `https://app.prepai.io/register?ref=${uniqueId}`;
                  Swal.fire({
                    icon: "success",
                    title: "Link copied to clipboard!",
                    confirmButtonText: "Okay, got it",
                    confirmButtonColor: "#161A34",
                  });
                }}
              >
                Copy link &nbsp; <FontAwesomeIcon icon={faCopy} />{" "}
              </button>
            </div> */}
          </div>
          <div className={""}>
            {" "}
            <img
              height={"420"}
              alt="fold-1-img"
              src="img/Earn_2520with_2520Every_2520referral3_2520_3_.jpg"
            />
          </div>
        </div>
        <div className={styles.foldmid} style={{ position: "relative" }}>
          <img
            height={"170"}
            width={"180"}
            alt="fold-1-img"
            style={{
              position: "relative",
              float: "left",
              top: "-6rem",
              left: "-2rem",
            }}
            src="img/greenarrow.png"
          />
          <div className={styles.copybox}>
            <h1>Copy and share your link</h1>
            <div className="d-flex" style={{ marginTop: "20px" }}>
              <input
                className={styles.copybox__input}
                disabled={true}
                value={`https://app.prepai.io/register?ref=${uniqueId}`}
              />{" "}
              &nbsp;&nbsp;&nbsp;
              <CopyToClipboard
                text={`https://app.prepai.io/register?ref=${uniqueId}`}
                onCopy={() =>
                  Swal.fire({
                    icon: "success",
                    title: "Link copied to clipboard!",
                    confirmButtonText: "Okay, got it",
                    confirmButtonColor: "#161A34",
                  })
                }
              >
                <button className={styles.copybox__btn}>
                  Copy Link &nbsp;
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </CopyToClipboard>
            </div>
            <br></br>
            <p className="d-flex align-items-center">
              Share the link on:{" "}
              <p className={styles.socialIcons}>
                <ul className={styles.listIcons}>
                  <li>
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size="xl"
                      color="#43BEA1"
                      onClick={() => handleSocialIconClick("facebook")}
                    />
                  </li>
                  <li>
                    {" "}
                    <FontAwesomeIcon
                      icon={faTwitter}
                      size="xl"
                      color="#43BEA1"
                      onClick={() => handleSocialIconClick("twitter")}
                    />
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      size="xl"
                      color="#43BEA1"
                      onClick={() => handleSocialIconClick("linkedin")}
                    />
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      size="xl"
                      color="#43BEA1"
                      onClick={() => handleSocialIconClick("whatsapp")}
                    />
                  </li>
                </ul>
              </p>
            </p>
          </div>
        </div>

        <div className={styles.fold2}>
          <div className={styles.textContainer2}>
            <h1>How do you get paid for your referrals?</h1>
            <p style={{ fontWeight: "400" }}>
              Based on our monthly and yearly subscription plans, as well as
              both solo and team options, <br /> our commission structure is
              designed to be straightforward yet rewarding. Here's how you can
              benefit from it.{" "}
            </p>
          </div>
          <br />

          <PrepaiTable newBoxShadow={true} width={"75%"} />
        </div>
        <br />
      </div>
      <Footer />
    </>
  );
};

export default AffiliateWidget;
