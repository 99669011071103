import React from "react";
import InfoPage from "./Sections/InfoPage/InfoPage";
import Partners from "./Sections/Partners/Partners";
import Works from "./Sections/Works/Works";
// import Promote from "./Sections/Promote/Promote";
import Table from "./Sections/Table/Table";
import TagLine from "./Sections/Tagline/TagLine";
import FAQ from "./Sections/faq/faq";
import Footer from "./util/Footer/Footer";
import Testimonial from "./Sections/Testimonial/TestimonialDesign";
import { PrepAIFoldFour } from "./Sections/PrepAIFoldFour/PrepAIFoldFour";

const Main = () => {
  return (
    <>
      <InfoPage></InfoPage>{" "}
      {/* Redirecting to InfoPage of "Make revenue of your lifetime with DataToBiz Affiliate......"" */}
      <Partners></Partners>{" "}
      {/* Redirecting to Partners section as "Why partner with DataToBiz Affiliate?.... "" */}
      <Works></Works>
      <PrepAIFoldFour />
      {/* For section of  "How it works? (The APE Structure).... "*/}
      <Testimonial></Testimonial>
      <TagLine></TagLine>
      {/* Blue Color tagline as "Join the Affiliate Program Today" */}
      {/*<Promote></Promote> */}{" "}
      {/* Blue Color tag as "Help your affiliates in becoming data driven with" */}
      <FAQ></FAQ> {/* FAQ Element */}
      {/* <Table></Table>{" "} */}
      {/* Image and its content shown as "Who can become an affiliate partner?"" */}
      <Footer />
    </>
  );
};

export default Main;
