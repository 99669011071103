import React, { useState, useEffect, useRef } from "react";
import "./referral_form.css";
import { api, setAuthToken } from "../../Api";
import { notifyError, notifySuccess } from "../util/Toaster/Toaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import { countryList } from "../../helpers/countryList";

function ReferralForm({ showVideo }) {
  const emailRef = useRef();
  // const [techOther,setTechOther] = useState(false)
  const [formData, setFormData] = useState({
    full_name: "",
    company: "",
    company_address: "",
    email: "",
    phone_number: "",
    country_code: "IN",
    dial_code: "+91",
    formatted_phone_number: "+91",
    team_size: "",
    technology: "",
    position_type: "",
    is_indian: "",
    job_opening: "",
    others: "",
    any_other_details: "",
    file: null,
    resources: [
      {
        team_size: "",
        technology: "",
        file: "",
        techOther: false,
        otherexpertise: "",
      },
    ],
    country: "",
    designation: "",
  });
  let phoneRef = useRef();

  const [loading, setLoading] = useState(false);

  const [showOthersInput, setShowOthersInput] = useState(false);

  const [teamOptions, setTeamOptions] = useState([
    { name: "1-5", disabled: false },
    { name: "6-10", disabled: false },
    { name: "11-15", disabled: false },
    { name: "15-20", disabled: false },
    { name: "20+", disabled: false },
  ]);

  const [techOptions, setTechOption] = useState([
    { name: "AI/ML Engineers", disabled: false },
    { name: "NLP Engineers", disabled: false },
    { name: "Azure Data Engineers", disabled: false },
    { name: "LLM Engineers", disabled: false },
    { name: "Instant IT Expertise", disabled: false },
    { name: "Power BI/ Tableau Developers", disabled: false },
    { name: "Google Cloud Platform Experts", disabled: false },
    { name: "Computer Vision Engineers", disabled: false },
    { name: "AWS Data Engineers", disabled: false },
    // { name: "Other", disabled: false },
  ]);

  const resourceTypeRef = useRef(null);

  // Handle the "Add More" button click
  const handleAddMore = () => {
    const size = formData.resources.length + 1;
    if (size <= techOptions?.length + 6) {
      const newResource = {
        team_size: "",
        technology: "",
        file: "",
      };

      // Update the state to append the new resource
      setFormData({
        ...formData,
        resources: [...formData.resources, newResource],
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "full_name") {
      const regex = /^[A-Za-z\s]*$/;
      if (regex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const maxSizeInBytes = 1024 * 1024 * 5; // 5 MB (change this to your desired limit)

  //     if (file.size > maxSizeInBytes) {
  //       notifyError(
  //         "File size exceeds the limit 5 MB. Please choose a smaller file."
  //       );
  //       return;
  //     }
  //   }
  //   setFormData({ ...formData, file: file });
  // };

  const handleFileChange = (e) => {
    const index = e.target.getAttribute("data-index");
    const updatedResources = [...formData.resources];
    updatedResources[index] = {
      ...updatedResources[index],
      file: e.target.files[0], // Assign the selected file to the resource
    };

    setFormData({
      ...formData,
      resources: updatedResources,
    });
  };

  const handleOnChange = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      setFormData({
        ...formData,
        phone_number: value,
        formatted_phone_number: formattedValue,
        country_code: data.countryCode,
        dial_code: "+" + data.dialCode,
      });
    }
  };

  const handleTeamOptions = teamOptions.map((state, index) => (
    <option
      key={index}
      label={state.name}
      value={state.name}
      disabled={state.disabled}
    >
      {state.name}
    </option>
  ));

  const handleTechOptions = techOptions.map((value, index) => (
    <option
      key={index}
      label={value.name}
      value={value.name}
      disabled={value.disabled}
    >
      {value.name}
    </option>
  ));

  useEffect(() => {
    console.log(formData?.resources);
  }, [formData.resources]);

  const handleResourcesChange = async (e, index, resourceType) => {
    const { name, value } = e.target;
    const updatedResources = [...formData.resources];

    if (resourceType == "tech" && value === "Other") {
      updatedResources[index] = {
        ...updatedResources[index],
        [name]: value,
        techOther: true,
      };
    } else if (
      resourceType == "tech" &&
      updatedResources[index].techOther &&
      name === "otherexpertise"
    ) {
      updatedResources[index] = {
        ...updatedResources[index],
        [name]: value,
      };
    } else if (resourceType == "tech") {
      updatedResources[index] = {
        ...updatedResources[index],
        [name]: value,
        techOther: false,
      };
    }

    if (resourceType == "team") {
      updatedResources[index] = {
        ...updatedResources[index],
        [name]: value,
      };
    }

    setFormData({
      ...formData,
      resources: updatedResources,
    });

    resourceTypeRef.current = resourceType;
  };

  useEffect(() => {
    const resourceType = resourceTypeRef.current;
    // This code will run after `formData` is updated.
    if (formData.resources.length > 0 && resourceType === "tech") {
      const updatedTechOptions = techOptions.map((option) => ({
        ...option,
        disabled: formData.resources.some(
          (item) => item.technology === option.name
        ),
      }));

      // Set the `disabled` flag to `false` for options not found in `formData.resources`
      updatedTechOptions.forEach((option) => {
        if (!option.disabled) {
          option.disabled = false;
        }
      });

      setTechOption(updatedTechOptions);
    }
  }, [formData, techOptions]);

  const removeResource = (e, indexToRemove) => {
    const indexOfSelectedTechOption = techOptions.findIndex(
      (option) => option.name === formData.resources[indexToRemove].technology
    );

    const updatedTechOptions = [...techOptions]; // Create a shallow copy of the array
    updatedTechOptions[indexOfSelectedTechOption] = {
      ...updatedTechOptions[indexOfSelectedTechOption],
      disabled: false,
    };
    setTechOption(updatedTechOptions);

    const updatedResources = [...formData.resources];
    updatedResources.splice(indexToRemove, 1);
    setFormData({
      ...formData,
      resources: updatedResources,
    });
  };

  const resetFormData = () => {
    setFormData({
      full_name: "",
      company: "",
      email: "",
      job_opening: "",
      phone_number: "",
      country_code: "IN",
      dial_code: "+91",
      formatted_phone_number: "+91",
      team_size: "",
      technology: "",
      position_type: "",
      is_indian: "",
      any_other_details: "",
      file: null,
      resources: [{ team_size: "", technology: "", file: "" }],
    });
  };

  // Validation function
  const validateForm = (formData) => {
    const errors = {};

    // Validate each form field
    if (!formData.full_name) {
      errors.full_name = "Full Name is required";
      return errors;
    }

    // if (!formData.company) {
    //   errors.company = "Company Name is required";
    //   return errors;
    // }

    if (!formData.email) {
      errors.email = "Email is required";
      return errors;
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
      return errors;
    }

    if (!formData.team_size) {
      errors.team_size = "Number of resources is required";
      return errors;
    }

    if (!formData.technology) {
      errors.technology = "Expertise Needed is required";
      return errors;
    }

    if (!formData.position_type) {
      errors.position_type = "Position Type is required";
      return errors;
    }

    if (!formData.is_indian) {
      errors.is_indian = "Please specify if you are an Indian Candidate";
      return errors;
    }

    if (!formData.job_opening) {
      errors.job_opening = "How you came to know about this is required";
      return errors;
    }

    if (formData.job_opening === "Others" && !formData.others) {
      errors.others = "Please specify other details";
      return errors;
    }

    // if (!formData.any_other_details) {
    //   errors.any_other_details = "Any Other Details is required";
    //   return errors;
    // }

    // Additional custom validations can be added as needed

    // return errors;
  };

  // Email validation function (you can use a library or a more robust regex pattern)
  const isValidEmail = (email) => {
    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const validationErrors = validateForm(formData);

    // if (Object.keys(validationErrors).length > 0) {
    //   // Display validation errors and prevent form submission
    //   notifyError(Object.values(validationErrors).join("\n"));
    //   return;
    // }

    setLoading(true);
    const requestFormData = new FormData();

    for (const key in formData) {
      if (key === "resources") {
        formData.resources.forEach((resource, index) => {
          for (const resourceKey in resource) {
            if (
              resourceKey === "technology" &&
              resource[resourceKey] === "Other"
            ) {
              const fieldKey = `resources[${index}][${resourceKey}]`;
              requestFormData.append(fieldKey, resource["otherexpertise"]);
            } else {
              const fieldKey = `resources[${index}][${resourceKey}]`;
              requestFormData.append(fieldKey, resource[resourceKey]);
            }
          }
        });
      } else {
        requestFormData.append(key, formData[key]);
      }
    }

    const referral_id = localStorage.getItem("referral_id");
    requestFormData.append("referral_id", referral_id);
    requestFormData.append(
      "role",
      formData?.is_indian === "Yes" ? "Contractual" : "Full-time"
    );
    // requestFormData.delete("is_indian");
    try {
      const response = await api.post(
        "/referrals/become_affiliate",
        // {
        //   ...formData,
        //   referral_id,
        // }
        requestFormData
      );
      notifySuccess(`Referrals submitted successfully!`);
      resetFormData();
    } catch (error) {
      console.error("Error making in referrals:", error);
      notifyError("Something went wrong.");
    }
    setLoading(false);
    Swal.fire({
      title: "Your Referral details were submitted successfully!",
      text: "Our team will review the information for moderation. The updates will be shared on your account email.",
      icon: "success",
      customClass: 'swal-wide',
      confirmButtonText: "Okay, got it",
      confirmButtonColor: "#49bfa1",
    }).then((res) => window.location.reload());
  };

  const handleSelection = (e) => {
    const { name, value } = e.target;
    if (value === "Others") setShowOthersInput(true);
    else setShowOthersInput(false);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (!formData.full_name) {
      notifyError("Name is required");
      return;
    }

    if (!formData.email) {
      notifyError("Email is required");
      return;
    } else if (!isValidEmail(formData.email)) {
      emailRef.current.focus({
        focusVisible: true,
      });
      notifyError("Invalid email format");
      return;
    }

    // if (!formData.company) {
    //   notifyError("Company Name is required");
    //   return;
    // }

    for (let index = 0; index < formData.resources.length; index++) {
      const resource = formData.resources[index];

      if (resource.team_size === "") {
        notifyError("Please Select Resources Needed");
        return;
      }
      if (resource.technology === "") {
        notifyError("Please Select Exptertise Needed");
        return;
      }
      if (resource.technology === "Other" && resource.otherexpertise === "") {
        notifyError("Other Expertise is required.");
        return;
      }
      // if (resource.file === "") {
      //   notifyError("Please Select File");
      //   return;
      // }
    }

    if (!formData.position_type) {
      notifyError("Type of Position is required");
      return;
    }
    if (!formData.is_indian) {
      notifyError("Is this a contract or full-time position is required");
      return;
    }
    if (!formData.job_opening) {
      notifyError("Please select an option in How you came to know about this Job");
      return;
    }
    if (formData.job_opening === "Others") {
      if (!formData.others) {
        notifyError("Please input others");
        return;
      }
    }

    // if (!formData.any_other_details) {
    //   notifyError("Please fill out Any Other detials.");
    //   return;
    // }
    handleSubmit(e);
  };
  const sourcesArr = [
    "Search Engine (Google, Bing, Yahoo)",
    "Google Ads",
    "Facebook",
    "Youtube",
    "Twitter",
    "Instagram",
    "LinkedIn",
    "Blog/Article",
    "Email",
    "Word of Mouth",
    "Others",
  ];

  useEffect(() => {
    if (phoneRef) {
      let childElement =
        phoneRef.current.dropdownContainerRef.querySelector(".selected-flag");
      childElement?.setAttribute("tabindex", "-1");
    }
  }, []);

  return (
    <div className="fold-3">
      <div className="loaded" style={{ marginRight: showVideo && "1rem" }}>
        <div className="hs-form-heading">
          <h3>No hassle earning!</h3>
          <span style={{ display: "block", padding: "0 21px" }}>
            Please provide the details of a company or individual actively
            seeking skilled professionals to enhance their team in AI, BI,
            Digital Transformation, Data Analytics, and engineering.
          </span>
        </div>
        <div>
          <div id="form-target" data-hs-forms-root="true">
            <form
              id="hs-form"
              className="hs-form"
              data-instance-id="dcbc0cd7-bdda-44cf-ba95-1e8d3e4056c5"
              data-form-id="a8ef0371-2b5e-4eac-8fe1-d6dbbe555d0a"
              data-portal-id="21707009"
              lang="en"
              data-hs-cf-bound="true"
              // onSubmit={handleSubmit}
            >
              <div className="hs-form__pagination-content-container">
                <div className="hs-form__row">
                  <div className="hs-form__group">
                    <div className="hs-form__field-row">
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-message hs-message hs-form__field--error">
                          <label
                            id="firstname-label"
                            htmlFor="firstname-input"
                            className="hs-form__field__label"
                            data-required="true"
                          >
                            <span>Name</span>
                            <span className="hs-form__field__label__required">
                              *
                            </span>
                          </label>
                          <input
                            id="firstname-input"
                            className="hs-form__field__input hs-form__field__input--error"
                            type="text"
                            maxLength={100}
                            name="full_name"
                            required={true}
                            // autoComplete="given-name"
                            // inputMode="text"
                            aria-invalid="true"
                            // pattern="[A-Za-z]+"
                            aria-required="true"
                            value={formData.full_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div
                        className="hs-form__field-row__column"
                        id="email-input"
                      >
                        <div
                          className="hs-form__field hs-form__field-email hs-email"
                          id="email-input"
                        >
                          <label
                            id="email-label"
                            htmlFor="email-input"
                            className="hs-form__field__label"
                            data-required="true"
                          >
                            <span>Email</span>
                            <span className="hs-form__field__label__required">
                              *
                            </span>
                          </label>
                          <input
                            id="email-input"
                            className="hs-form__field__input"
                            type="email"
                            ref={emailRef}
                            name="email"
                            // autoFocus
                            // autoComplete="email"
                            // inputMode="email"
                            // aria-invalid="false"
                            // aria-required="true"
                            // aria-describedby="email-input"
                            value={formData.email}
                            onChange={handleInputChange}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hs-form__row">
                  <div className="hs-form__group">
                    <div className="hs-form__field-row">
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-company hs-company">
                          <label
                            id="company-label"
                            htmlFor="company-input"
                            className="hs-form__field__label"
                            data-required="false"
                          >
                            <span>Company Name</span>
                            {/* <span className="hs-form__field__label__required">
                              *
                            </span> */}
                          </label>
                          <input
                            id="company-input"
                            className="hs-form__field__input"
                            type="text"
                            maxLength={100}
                            name="company"
                            autoComplete="organization"
                            inputMode="text"
                            aria-invalid="false"
                            aria-required="false"
                            value={formData.company}
                            onChange={handleInputChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-phone hs-phone">
                          <label
                            id="phone-label"
                            htmlFor="phone-input"
                            className="hs-form__field__label"
                            data-required="true"
                          >
                            <span>Phone number</span>
                            {/* <span className="hs-form__field__label__required">
                              *
                            </span> */}
                          </label>

                          <PhoneInput
                            ref={phoneRef}
                            country={formData.country_code}
                            // countryCodeEditable={false}
                            placeholder="Enter phone number"
                            value={formData.formatted_phone_number}
                            onChange={handleOnChange}
                            inputClass="custom-phone-input"
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hs-form__row">
                  <div className="hs-form__group">
                    <div className="hs-form__field-row">
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-company hs-company">
                          <label
                            id="company-address-label"
                            htmlFor="company_address"
                            className="hs-form__field__label"
                            data-required="false"
                          >
                            <span>Company Address</span>
                          </label>
                          <input
                            id="company_address"
                            className="hs-form__field__input"
                            type="text"
                            maxLength={100}
                            name="company_address"
                            autoComplete="organization"
                            inputMode="text"
                            aria-invalid="false"
                            aria-required="false"
                            value={formData.company_address}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-company hs-company">
                          <label
                            id="company-address-label"
                            htmlFor="company_address"
                            className="hs-form__field__label"
                            data-required="false"
                          >
                            <span>Country</span>
                          </label>
                          <select
                            id="how_many_resources_do_you_need_-input"
                            className="hs-form__field__input"
                            name="country"
                            required={true}
                            value={formData.country}
                            onChange={handleInputChange}
                          >
                            <option label="Select Country" disabled="" value="">
                              Select Country
                            </option>
                            {countryList.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hs-form__row">
                  <div className="hs-form__group">
                    <div className="hs-form__field-row">
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-company hs-company">
                          <label
                            id="company-address-label"
                            htmlFor="company_address"
                            className="hs-form__field__label"
                            data-required="false"
                          >
                            <span>Designation</span>
                          </label>
                          <input
                            id="designation"
                            className="hs-form__field__input"
                            type="text"
                            maxLength={100}
                            name="designation"
                            autoComplete="designation"
                            inputMode="text"
                            aria-invalid="false"
                            aria-required="false"
                            value={formData.designation}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {formData.resources.map((resource, index) => (
                  <>
                    <div
                      style={{
                        background: "#f4f4f4",
                        padding: "12px 12px 2px 12px",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-how_many_resources_do_you_need_ hs-how_many_resources_do_you_need_">
                                <label
                                  id="how_many_resources_do_you_need_-label"
                                  htmlFor="how_many_resources_do_you_need_-input"
                                  className="hs-form__field__label"
                                  data-required="true"
                                >
                                  <span>Number of Resources</span>
                                  <span className="hs-form__field__label__required">
                                    *
                                  </span>
                                </label>
                                <div
                                  id="how_many_resources_do_you_need_-description"
                                  className="hs-form__field__description"
                                >
                                  Choose team size
                                </div>
                                <select
                                  id="how_many_resources_do_you_need_-input"
                                  className="hs-form__field__input"
                                  name="team_size"
                                  required={true}
                                  aria-invalid="false"
                                  aria-describedby="how_many_resources_do_you_need_-description"
                                  aria-required="true"
                                  value={resource.team_size}
                                  // onChange={handleInputChange}
                                  onChange={(e) =>
                                    handleResourcesChange(e, index, "team")
                                  }
                                >
                                  <option
                                    label="Please Select"
                                    disabled=""
                                    value=""
                                  >
                                    Please Select
                                  </option>
                                  {handleTeamOptions}
                                </select>
                              </div>
                            </div>
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-technology hs-technology">
                                <label
                                  id="technology-label"
                                  htmlFor="technology-input"
                                  className="hs-form__field__label d-flex"
                                  data-required="true"
                                >
                                  <span>Expertise needed</span> 
                                  <span className="hs-form__field__label__required">
                                    *
                                  </span>
                                  {index > 0 && (
                                    <div className="custom-icon-div">
                                      <FontAwesomeIcon
                                        style={{ fontSize: "20px", margin: 0, position:"relative", top:'-1rem', right:"-9rem" }}
                                        className="custom-icon"
                                        icon={faXmarkCircle}
                                        onClick={(e) =>
                                          removeResource(e, index)
                                        }
                                      />
                                    </div>
                                  )}
                                </label>
                                <div
                                  id="technology-description"
                                  className="hs-form__field__description"
                                >
                                  Select Technology
                                </div>
                                <div style={{ display: "flex" }}>
                                  <select
                                    id="technology-input"
                                    className="hs-form__field__input"
                                    name="technology"
                                    required={true}
                                    aria-invalid="false"
                                    aria-describedby="technology-description"
                                    aria-required="true"
                                    value={resource.technology}
                                    // onChange={handleInputChange}
                                    onChange={(e) =>
                                      handleResourcesChange(e, index, "tech")
                                    }
                                  >
                                    <option
                                      label="Please Select"
                                      disabled=""
                                      value=""
                                    >
                                      Please Select
                                    </option>
                                    {handleTechOptions}
                                    <option
                                      label="Other"
                                      disabled=""
                                      value="Other"
                                    >
                                      Other
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {resource?.techOther && (
                        <div className="hs-form__row">
                          <div className="hs-form__group">
                            <div className="hs-form__field-row">
                              <div className="hs-form__field-row__column">
                                <div className="hs-form__field hs-form__field-company hs-company">
                                  <label
                                    id="company-address-label"
                                    htmlFor="company_address"
                                    className="hs-form__field__label"
                                    data-required="false"
                                  >
                                    <span>Other Expertise</span>
                                    <span className="hs-form__field__label__required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    id="otherexpertise"
                                    className="hs-form__field__input"
                                    type="text"
                                    maxLength={100}
                                    name="otherexpertise"
                                    autoComplete="otherexpertise"
                                    placeholder="Please enter other expertise"
                                    inputMode="text"
                                    aria-invalid="false"
                                    aria-required="false"
                                    value={formData.otherexpertise}
                                    onChange={(e) =>
                                      handleResourcesChange(e, index, "tech")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className="hs-form__row"
                        key={`jd-container-${index}`}
                        id={`jd-id-${index}`}
                      >
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-company hs-company">
                                <label
                                  id="company-label"
                                  htmlFor="company-input"
                                  className="hs-form__field__label"
                                  data-required="false"
                                >
                                  <span>Upload Job Description</span>
                                  {/* <span className="hs-form__field__label__required">
                                  *
                                </span> */}
                                </label>

                                <div
                                  id="upload_job_description"
                                  className="hs-form__field__description"
                                >
                                  Maximum File Size: 5MB - Please make sure your
                                  file meets this size restriction. (PDF, DOCX,
                                  DOC)
                                </div>

                                <input
                                  id={`jd-input-${index}`}
                                  className="hs-form__field__input"
                                  name="file"
                                  type="file"
                                  accept=".pdf, .docx, .doc"
                                  // required={true}
                                  data-index={index}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    const allowedExtensions = [
                                      "pdf",
                                      "docx",
                                      "doc",
                                    ];
                                    const fileExtension = file.name
                                      .split(".")
                                      .pop()
                                      .toLowerCase();
                                    if (
                                      !allowedExtensions.includes(fileExtension)
                                    ) {
                                      notifyError(
                                        "Only PDF, DOCX, DOC files are allowed"
                                      );
                                      e.target.value = "";
                                      return;
                                    } else {
                                      handleFileChange(e);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </>
                ))}

                <div className="hs-form__row">
                  <div className="hs-form__actions">
                    <button
                      type="button"
                      name="add_more"
                      className="hs-form__add__more"
                      onClick={handleAddMore}
                    >
                      +Add More
                    </button>
                  </div>
                </div>

                <div className="hs-form__row">
                  <div className="hs-form__group">
                    <div className="hs-form__field-row">
                      <div className="hs-form__field-row__column">
                        <div>
                          <label
                            id="company-label"
                            htmlFor="company-input"
                            className="hs-form__field__label"
                            data-required="false"
                          >
                            <span>Type of Position</span>
                            <span className="hs-form__field__label__required">
                              *
                            </span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="position_type"
                              value="Remote"
                              checked={formData.position_type === "Remote"}
                              required={true}
                              onChange={handleInputChange}
                            />
                            <span className="hs-form-radio-pad-l">Remote</span>
                          </label>
                          <br />

                          <label>
                            <input
                              type="radio"
                              name="position_type"
                              value="On-site Role"
                              checked={
                                formData.position_type === "On-site Role"
                              }
                              required={true}
                              onChange={handleInputChange}
                            />
                            <span className="hs-form-radio-pad-l">
                              On-site Role
                            </span>
                          </label>
                          <br />
                        </div>
                      </div>
                      <div className="hs-form__field-row__column">
                        <div>
                          <label
                            id="company-label"
                            htmlFor="company-input"
                            className="hs-form__field__label"
                            data-required="false"
                          >
                            <span>
                              Is this a contract or full-time position?{" "}
                              <span className="hs-form__field__label__required">
                                *
                              </span>
                            </span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="is_indian"
                              value="Yes"
                              checked={formData.is_indian === "Yes"}
                              onChange={handleInputChange}
                            />
                            <span className="hs-form-radio-pad-l">
                              Contractual
                            </span>
                          </label>
                          <br />

                          <label>
                            <input
                              type="radio"
                              name="is_indian"
                              value="No"
                              checked={formData.is_indian === "No"}
                              onChange={handleInputChange}
                            />
                            <span className="hs-form-radio-pad-l">
                              Full-time
                            </span>
                          </label>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="hs-form__row">
                  <div className="hs-form__group">
                    <div className="hs-form__field-row">
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-company hs-company">
                          <label
                            id="company-label"
                            htmlFor="company-input"
                            className="hs-form__field__label"
                            data-required="false"
                          >
                            <span>Upload Job Description</span>
                            <span className="hs-form__field__label__required">
                              *
                            </span>
                          </label>

                          <div
                            id="upload_job_description"
                            className="hs-form__field__description"
                          >
                            Maximum File Size: 5MB - Please make sure your file
                            meets this size restriction.
                          </div>

                          <input
                            id="jd-input"
                            className="hs-form__field__input"
                            name="file"
                            type="file"
                            accept=".pdf, .docx, .doc"
                            onChange={handleFileChange}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="hs-form__row">
                  <div className="hs-form__group">
                    <div className="hs-form__field-row">
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-message hs-message hs-form__field--error">
                          <label
                            id="message-label"
                            htmlFor="message-input"
                            className="hs-form__field__label"
                            data-required="true"
                          >
                            <span>How did you come to know about this Job? </span>
                            <span className="hs-form__field__label__required">
                              *
                            </span>
                          </label>
                          {/* <input
                            id="message-input"
                            className="hs-form__field__input hs-form__field__input--error"
                            name="job_opening"
                            required={true}
                            aria-invalid="true"
                            aria-required="true"
                            type="text"
maxLength={100}
                            value={formData.job_opening}
                            onChange={handleInputChange}
                          /> */}

                          <select
                            id="message-input"
                            className="hs-form__field__input hs-form__field__input--error"
                            name="job_opening"
                            required={true}
                            aria-invalid="false"
                            aria-describedby="how_many_resources_do_you_need_-description"
                            aria-required="true"
                            value={formData.job_opening}
                            onChange={handleSelection}
                            style={{ height: "41px" }}
                          >
                            <option label="Please Select" disabled="" value="">
                              Please Select
                            </option>
                            {sourcesArr.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {showOthersInput && (
                        <div className="hs-form__field-row__column">
                          <div className="hs-form__field hs-form__field-email hs-email">
                            <label
                              id="email-label"
                              htmlFor="email-input"
                              className="hs-form__field__label"
                              data-required="true"
                            >
                              <span>Other Source</span>
                              <span className="hs-form__field__label__required">
                                *
                              </span>
                            </label>
                            {/* <br /> */}
                            <input
                              id="others-input"
                              className="hs-form__field__input "
                              type="text"
                              maxLength={100}
                              placeholder="Please enter other source"
                              name="others"
                              aria-invalid="true"
                              aria-required="true"
                              value={formData.others}
                              onChange={handleInputChange}
                              required={true}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hs-form__row">
                  <div className="hs-form__group">
                    <div className="hs-form__field-row">
                      <div className="hs-form__field-row__column">
                        <div className="hs-form__field hs-form__field-message hs-message hs-form__field--error">
                          <label
                            id="message-label"
                            htmlFor="message-input"
                            className="hs-form__field__label"
                            data-required="true"
                          >
                            <span>Any Other Details</span>
                            {/* <span className="hs-form__field__label__required">
                              *
                            </span> */}
                          </label>
                          <textarea
                            id="message-input"
                            className="hs-form__field__input hs-form__field__input--error"
                            name="any_other_details"
                            required={true}
                            aria-invalid="true"
                            aria-required="true"
                            rows="3"
                            cols="50"
                            maxLength={1000}
                            value={formData.any_other_details}
                            onChange={handleInputChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loading && (
                  <div className="loader-container ">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                <div className="hs-form__row">
                  <div className="hs-form__actions">
                    <button
                      type="submit"
                      name="Submit"
                      className="hs-form__actions__submit submit-btn-referral-team"
                      onClick={(e) => handleSubmitClick(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralForm;
